import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Link, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import TableLoading from '../../../components/loaders/TableLoading';
import DateFormatter from '../../../components/utils/DateFormatter';
import LoadingButton from '../../../components/buttons/Button';
import NameAvatar from '../../../components/avatars/Avatar';
import NATable from '../../../components/utils/NATable';
import NA from '../../../components/utils/NA';

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import LoopIcon from '@mui/icons-material/Loop';

import { 
  GetPayments, 
  SubmitPaymentForApproval
} from '../../../redux/actions/pay/paymentsActions';

import CreateBillModal from '../billsPage/CreateBillModal';
import PaymentDetailsModal from './PaymentDetailsModal';
import CreateInternationalPaymentModal from './CreateInternationalPaymentModal';
import CreatePaymentModal from './CreatePaymentModal';

import global from "../../../global.scss";
import "./styles.scss";

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true); 
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusFilter, setStatusFilter] = useState("");
  const [paymentDetailsModalOpen, setPaymentDetailsModalOpen] = useState(false);
  const [createInternationalPaymentModalOpen, setCreateInternationalPaymentModalOpen] = useState(false);
  const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);
  const [createBillModalOpen, setCreateBillModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsTotal, setPaymentsTotal] = useState(0);

  const [selectedPaymentsList, setSelectedPaymentsList] = useState([]);

  const [sendId, setSendId] = useState(null);
  const [sending, setSending] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Vendor",
      sort: "ref_vendor_id",
    },
    {
      label: "Bill",
      sort: "invoice_number",
    },
    // {
    //   label: "Delivery method",
    //   sort: "ref_delivery_method_id",
    // },
    {
      label: "Debit date",
      sort: "debit_date",
    },
    {
      label: "Delivery date",
      sort: "delivery_date",
    },
    {
      label: "Amount",
      sort: "amount",
      align: "right"
    },
    {
      label: "",
      sort: null
    }
  ];

  if(statusFilter === ""){
    tableHeadingsList.splice(4, 0,  {
      label: "Status",
      sort: "status",
    });
  }

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetPayments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, statusFilter]);

  useEffect(() => {
    try {
      console.log(state);
      formatPaymentsList(state.paymentsList.records || []);
      setPaymentsTotal(state.paymentsList.total || 0);
    } catch (err) {}
  }, [state]);

  // -------------------- list formatter --------------------
  const formatPaymentsList = (list) => {
    setPaymentsList(list);
  } 

  async function handleGetPayments(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    if(statusFilter) query += `&status__in=${statusFilter}`;
    dispatch(GetPayments(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleGetPaymentDetails = (obj) => {
    setModalTitle("");
    setUpdate({
      ...obj,
      defaultMode: 1
    });
    setPaymentDetailsModalOpen(!paymentDetailsModalOpen);
  }; 

  // const handleDeliveryMethod = (method) => {
  //   switch(method){
  //     case "INSTANT_TRANSFER": return "Instant Transfer";
  //     case "SAME_DAY_ACH": return "Same day ACH";
  //     case "ONE_DAY_ACH": return "One day ACH";
  //     case "STANDARD_ACH": return "ACH";
  //     case "DOMESTIC_WIRE": return "Domestic wire";

  //     default: return null;
  //   }
  // };

  const handleSendToApprover = (id) => {
    setSendId(id);
    let obj = {
      "payments_list": [id]
    };
    dispatch(SubmitPaymentForApproval(companyId, obj, setSending)).then(() => {
      handleGetPayments(setSending);
    });
  };

  const handleMultiSendToApprover = () => {
    let obj = {
      "payments_list": selectedPaymentsList.map(i => {
        return i;
      })
    };
    dispatch(SubmitPaymentForApproval(companyId, obj, setSending)).then(() => {
      handleGetPayments(setSending).then(() => {
        handleClearSelectedPaymentsList();
      })
    });
  };

  const handleClearSelectedPaymentsList = () => {
    setSelectedPaymentsList([]);
  };

  const getPaymentChipColor = (status) => {
    switch(status){
      case "Pending 1st approval": return "warning";
      case "Pending 2nd approval": return "warning";
      case "Rejected 1st approval": return "error";
      case "Rejected 2nd approval": return "error";
      case "Scheduled": return "info";
      case "Processing": return "info";
      case "Paid": return "success";
      case "Failed": return "error";

      default: return "default";
    }
  };

  return (
    <Box>

      <PaymentDetailsModal 
        open={paymentDetailsModalOpen}
        setOpen={setPaymentDetailsModalOpen}
        title={modalTitle}
        update={update}
        />
        
      <CreateInternationalPaymentModal 
        open={createInternationalPaymentModalOpen}
        setOpen={setCreateInternationalPaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayments}
        />

      <CreatePaymentModal 
        open={createPaymentModalOpen}
        setOpen={setCreatePaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayments}
        />

      <CreateBillModal
        open={createBillModalOpen}
        setOpen={setCreateBillModalOpen}
        title={modalTitle}
        onDomesticPayment={(e) => {
          setUpdate(e);
          setCreateBillModalOpen(false);
          setCreatePaymentModalOpen(true);
        }}
        onInternationalPayment={(e) => {
          setUpdate(e);
          setCreateBillModalOpen(false);
          setCreateInternationalPaymentModalOpen(true);
        }}
        update={update}
        handleGet={handleGetPayments}
        />   

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box  
          className="flexCenter_Row"
          gap="15px">
          <Chip 
            variant='outlined'
            color={statusFilter === "" ? 'info' : 'default'}
            label="All"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("")
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Pending 1st approval,Pending 2nd approval" ? 'warning' : 'default'}
            label="Pending approval"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("Pending 1st approval,Pending 2nd approval")
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Rejected 1st approval,Rejected 2nd approval" ? 'error' : 'default'}
            label="Rejected"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("Rejected 1st approval,Rejected 2nd approval");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Scheduled" ? 'info' : 'default'}
            label="Scheduled"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("Scheduled");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Processing" ? 'info' : 'default'}
            label="Processing"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("Processing");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Paid" ? 'success' : 'default'}
            label="Paid"
            onClick={() => {
              handleClearSelectedPaymentsList();
              setStatusFilter("Paid");
            }}
            />
        </Box>
        <Box
          className="flexCenter_Row" 
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create Bill");
              setCreateBillModalOpen(!createBillModalOpen);
            }}>
            Add Payment
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <>
          {
            selectedPaymentsList.length > 0
            &&
            <Box
              className="payment__multiselect flexCenterSBRow">

              <Typography
                variant='body2'
                fontWeight={600}>
                {selectedPaymentsList.length} Payment selected
              </Typography>

              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <LoadingButton
                  variant="contained"
                  loading={sending}
                  disabled={sending}
                  onClick={handleMultiSendToApprover}>
                  Send selected for approval
                </LoadingButton>
              </Box>
            </Box>
          }
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  (statusFilter === "Rejected 1st approval,Rejected 2nd approval")
                  &&
                    paymentsList.length > 0
                    &&
                    <TableCell width={"60px"}>
                      <Checkbox 
                        checked={selectedPaymentsList.length === paymentsList.length}
                        onChange={(e) => {
                          if(e.target.checked === true){
                            let temp = paymentsList.map(i => {
                              return i.payment_id
                            });
                            setSelectedPaymentsList([ ...temp ]);
                          } else {
                            setSelectedPaymentsList([]);
                          }
                        }}
                        />
                    </TableCell>
                }
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paymentsList.length === 0
                ?
                <NATable
                  label={"No payment found"}
                  />
                :
                paymentsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    {
                      (statusFilter === "Rejected 1st approval,Rejected 2nd approval")
                      &&
                      <TableCell width={"60px"}>
                        <Checkbox 
                          checked={selectedPaymentsList.includes(row.payment_id)}
                          onChange={(e) => {
                            if(e.target.checked === true){
                              let temp = selectedPaymentsList;
                              temp.push(row.payment_id);
                              setSelectedPaymentsList([ ...temp ]);
                            } else {
                              let temp = selectedPaymentsList;
                              temp = temp.filter(i => i !== row.payment_id);
                              setSelectedPaymentsList([ ...temp ]);
                            }
                          }}
                          />
                      </TableCell>
                    }
                    <TableCell>
                      {
                        row.ref_vendor_id
                        ?
                        <Box
                          className="flexCenter_Row"
                          gap={"10px"}>
                          <NameAvatar
                            color={row.ref_vendor_id?.color || null}>
                            {row.ref_vendor_id?.name || "NA"}
                          </NameAvatar>
                          <Typography>
                            {row.ref_vendor_id?.name || "NA"}
                          </Typography>
                          {
                            row.ref_vendor_id?.is_international === true
                            &&
                            <Tooltip
                              title={"International vendor"}>
                              <LanguageIcon 
                                sx={{ color: "lightgray" }}
                                fontSize='small'/>
                            </Tooltip>
                          }
                        </Box>
                        :
                        <i style={{ color: "grey" }}>No supplier found</i>
                      }
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant='body2'>
                          {(row?.ref_bill_id?.invoice_number && row?.ref_bill_id?.invoice_number !== "NA")
                            ? <Typography variant='body2'>#{row?.ref_bill_id?.invoice_number }</Typography>
                            : <i style={{ color: "grey" }}>No invoice #</i>}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/* <TableCell>
                      {handleDeliveryMethod(row?.ref_delivery_method_id?.delivery_method) || <NA />}
                    </TableCell> */}
                    <TableCell>{row.debit_date && DateFormatter(row.debit_date)}</TableCell>
                    <TableCell>{row.delivery_date && DateFormatter(row.delivery_date)}</TableCell>
                    {
                      statusFilter === ""
                      &&
                      <TableCell>
                        <Chip
                          size='small'
                          color={getPaymentChipColor(row.status)}
                          label={row.status || <NA />} 
                          />
                      </TableCell>
                    }
                    <TableCell
                      sx={{ pt: 0.5, pb: 0.5 }}
                      align='right'>
                      <Box>
                        <Typography variant='body2' fontWeight={600}>
                          {handleShowAmount(row.currency, row.amount)}
                        </Typography>
                      </Box>
                      {
                        row?.ref_bill_id?.is_recurring === true
                        && 
                        <Box 
                          className="flexCenterFERow"
                          color={"grey"}>
                          <LoopIcon fontSize='small'/>
                          <Typography variant='caption' color={"grey"}>
                            Recurring
                          </Typography>
                        </Box>
                      }
                    </TableCell>
                    <TableCell>
                      <Box
                        className='flexCenterCenterRow'
                        gap="30px">

                      <Link
                        onClick={() => handleGetPaymentDetails(row)}>View Payment</Link>

                      <Tooltip
                        title="Activity messages">
                        <IconButton
                          onClick={() => {
                            setModalTitle("Activity messages for payment");
                            setUpdate({
                              ...row,
                              defaultMode: 2
                            });
                            setPaymentDetailsModalOpen(!paymentDetailsModalOpen);
                          }}>
                          <MessageOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {
                        selectedPaymentsList.length > 0
                        ?
                        <></>
                        :
                          (row.status !== "Rejected 1st approval" &&  row.status !== "Rejected 2nd approval")
                          ?
                          <Box width={"40px"}/>
                          :
                            row?.payment_id === sendId && sending
                            ?
                            <CircularProgress size={26} sx={{ margin: "7px" }}/>
                            :
                            <IconButton
                              onClick={(e) => {
                                handleSendToApprover(row?.payment_id);
                              }}>
                              <SendTwoToneIcon sx={{ color: global['secondary_light']}}/>
                            </IconButton>
                        }
                         </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={paymentsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
          </>
      }
    </Box>
  )
}

export default PaymentsPage;