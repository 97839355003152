import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone';

import { 
  Box, 
  Chip, 
  Container, 
  Divider, 
  FormControl, 
  FormControlLabel, 
  FormLabel, 
  IconButton, 
  Radio, 
  RadioGroup, 
  Skeleton, 
  Typography, 
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import { 
  PayInvoice
} from '../../../redux/actions/makePayment/invoicesActions';
import { 
  GetServiceFeePrice,
  GetServicesForProduct,
  // GetDeliveryMethods, 
  // GetDeliveryMethodsPrice, 
} from '../../../redux/actions/pay/paymentsActions';
import { 
  GetCompanyBankAccounts 
} from '../../../redux/actions/pay/companyBankAccountsActions';
import { 
  GetHolidays 
} from '../../../redux/actions/miscellaneousActions';
import { 
  GetCompanies 
} from '../../../redux/actions/dashboard/companiesActions';

import { 
  SELECT_COMPANY 
} from '../../../redux/actionTypes';

import "../styles.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const LoggedInFlow = ({
  onBack,
  company, 
  invoice, 
  formData,
  setFormData,
  handleChange
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);
  const pay = useSelector(state => state.pay);
  const other = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const { 
    handleCalculateAmount, 
    handleShowAmount 
  } = useCurrencyService();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingServiceFeePrice, setLoadingServiceFeePrice] = useState(false);

  const [bankAccountsList, setBankAccountsList] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const [servicesList, setServicesList] = useState([]);
  const [serviceFees, setServiceFees] = useState([]);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [holidaysList, setHolidaysList] = useState([]);

  const [feeAmount, setFeeAmount] = useState(0);

  const [step, setStep] = useState(-1);
  // step = 1 - select bank account
  // step = 2 - select date & delivery method
  // step = 3 - review & confirm
  // step = 4 - payment paid or scheduled

  useEffect(() => {
    setStep(1);
    dispatch(GetHolidays(companyId, setLoading));

    try { 
      if(dashboard.companiesList === null){
        dispatch(GetCompanies(1, 500, "", setLoading)).then(({res, statusCode}) => {
          // auto company select
          if(statusCode === 200){
            if(res.data.records.length > 0){
              dispatch({ type: SELECT_COMPANY, payload: res.data.records[0] });
            }
          }
        });
      }
    } catch (err) {}

    // call bank accounts
    if (companyId) handleGetBankAccounts();


    setSelectedServiceId(null);
    setSelectedService(null);
    setSelectedBankAccount(null);
    return () => {
      setStep(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(pay.companyBankAccountsList.records || []);
      formatServicesList(pay.servicesForProductList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pay, step]);

  useEffect(() => {
    try {
      formatHolidaysList(other.holidaysList || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  // -------------- formatters --------------
  const formatServicesList = (list) => {
    setServicesList(list);
    if (step === 2) handleGetServiceFeePrice(list);
  };

  const formatCompanyBankAccountsList = (list) => {
    setBankAccountsList(list)
  };

  const formatHolidaysList = (list) => {
    setHolidaysList(list);

    // check if today is valid of not
    let today = dayjs()
    let addDays = 0;
    while(!findValidDebitDate(today, addDays, list)){
      addDays++;
    };
    setFormData({
      ...formData,
      Date: dayjs(today).add(addDays, 'day')
    });
  };


  // -------------- api calls --------------
  async function handleGetBankAccounts(customLoading){
    await dispatch(GetCompanyBankAccounts(companyId, 1, 500, customLoading ? customLoading : setLoading));
  };

  
  // -------------- utility functions ----------
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleGetServicesForProduct = () => {
    dispatch(GetServicesForProduct(companyId, "Receive", setLoading2)).then(() => {
      handleNextStep();
    });   
  };

  const handleGetServiceFeePrice = async (list) => {
    let rate = [];

    for (const service of list) {
      try { 
        let priceInInt = handleCalculateAmount(formData.Currency, formData.Price)

        await dispatch(GetServiceFeePrice(companyId, service.id, priceInInt, setLoadingServiceFeePrice))
          .then(({res, statusCode}) => {
          if(statusCode === 200){
            rate.push(res.data)
          }
        });
      } catch (error) {
        console.error(`Error fetching data for object with id ${service.id}:`, error);
      }
    }

    setServiceFees(rate);
  };

  const handleDeliveryMethod = (method) => {
    switch(method){
      case "INSTANT_TRANSFER": return "Instant Transfer";
      case "SAME_DAY_ACH": return "Same day ACH";
      case "ONE_DAY_ACH": return "One day ACH";
      case "NEXT_DAY_ACH": return "Next-day delivery"
      case "STANDARD_ACH": return "3 business days";
      case "DOMESTIC_WIRE": return "Domestic wire";

      default: return null;
    }
  };

  function findValidDebitDate(today, addDays, holidays){
    let date = dayjs(today).add(addDays, 'day');
    let isValid = true;

    holidays.forEach((i) => {
      if (i === date.format("YYYY-MM-DD")) {
        isValid = false;
      }
    });

    // to disable all weekends
    if(isValid){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        isValid = true;
      } else {
        isValid = false;
      };
    };

    return isValid;
  };

  const disableSpecificDates = (date) => {
    let disabled = false;

    holidaysList.forEach((i) => {
      if (i.date === date.format("YYYY-MM-DD")) {
        disabled = true;
      }
    });

    // to disable all weekends
    if(!disabled){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        disabled = false;
      } else {
        disabled = true;
      };
    };

    return disabled;
  };

  // -------------- handle submit --------------
  const handlePay = () => {
    try {
      let obj = {
        "ref_company_id": company.company_id,
        "currency": formData.Currency,
        "amount": handleCalculateAmount(formData.Currency, formData.Price || 0),
        "payer_bank_id": selectedBankAccount.id,
        "service_id": selectedServiceId,
        "debit_date": dayjs(formData.Date).format('YYYY-MM-DD'),
        "invoice_number": formData.Invoice
      };

      if(invoice){
        obj = {
          ...obj,
          "invoice_id": invoice.invoice_id,
        }
      }

      console.log(obj);

      // this company id should the company id of the guest user of logged in user.
      dispatch(PayInvoice(companyId, obj, setLoading)).then(({ res, statusCode }) => {
        setLoading(false);
        if(statusCode === 201){
          handleNextStep();
        }
      });

    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Box>
      {
        step === 1
        // select bank
        ?
        <Container
          maxWidth="sm">
          <Box
            className="flexCenter_Row"
            mt={"20px"}>
            <IconButton
              onClick={onBack}>
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Box>
          <Box
            className="flexCenter_Column"
            gap={"40px"}
            mt={"20px"}>
            <Typography
              variant='h4'
              textAlign={"center"}>
              How do you want to pay?
            </Typography> 
            <Typography
              textAlign={"center"}>
              <b>{handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}</b>
              &nbsp;to&nbsp;
              <b>{company?.company_name}</b>
            </Typography> 
            <Box
              className="flex__Column"
              width={"100%"}
              gap={"20px"}>
              <Typography
                textAlign={"left"}
                variant='subtitle2'>
                Bank accounts
              </Typography>
              {
                loading
                ?
                <>
                  <Skeleton
                    variant='rectangle'
                    sx={{ width: "100%", borderRadius: "16px", height: "92.5px",  }}/>
                </>
                : 
                  bankAccountsList.length === 0
                  ?
                  <Typography textAlign={"center"}>No bank account found</Typography>
                  :
                    bankAccountsList.map((item, index) => (
                      <Box
                        key={index}
                        className={`flexCenterSBRow ${item?.verified === true ? 'makepayment__bank' : 'makepayment__bank-disabled'}  
                          ${selectedBankAccount?.id === item.id && 'makepayment__bank-selected'}`}
                        onClick={item.verified === true 
                          ? () => setSelectedBankAccount(item)
                          : () => {}}>
                        <Box
                          className="flexCenter_Row" 
                          gap={"20px"}>
                          <AccountBalanceOutlinedIcon 
                            fontSize='large'/>
                          <Box>
                            <Box
                              className="flexCenter_Row"
                              gap={"10px"}>
                              <Typography 
                                variant='subtitle1'
                                fontWeight={600}>
                                Bank Account
                              </Typography>
                              {
                                item.is_default === true
                                &&
                                <Chip
                                  color='warning'
                                  size='small'
                                  label={'Primary'} 
                                  />
                              }
                              {
                                item.verified === false
                                &&
                                <Chip
                                  color='error'
                                  size='small'
                                  label={'Unverified'} 
                                  />
                              }
                            </Box>
                            <Typography
                              variant='body2'
                              color={"grey"}>
                              ...{item.account_number.slice(-4)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))
              }
            </Box>
            <LoadingButton
              variant="contained"
              disabled={selectedBankAccount === null}
              loading={loading2}
              onClick={handleGetServicesForProduct}>
              Continue
            </LoadingButton>
          </Box>
        </Container>
        :
          step === 2
          // select debit date & delivery method
          ?
          <Container
            maxWidth="sm">
            <Box
              className="flexCenter_Row"
              mt={"20px"}>
              <IconButton
                onClick={handlePrevStep}>
                <ArrowBackOutlinedIcon />
              </IconButton>
            </Box>
            <Box
              className="flexCenter_Column"
              gap={"30px"}>
              <Typography
                variant='h4'
                textAlign={"center"}
                mt={"20px"}>
                Choose the date you want this payment deducted.
              </Typography> 
              <Typography
                textAlign={"center"}>
                Choose the date for your payment to go out.
              </Typography> 
              <Box
                className="flexCenter_Column"
                width={"100%"}
                gap={"10px"}>
                <Box  
                  width={"340px"}
                  className="flexCenterCenterRow">
                  {/* <Box
                    className="flexCenter_Row"
                    gap={"5px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}>
                      Invoice Date
                    </Typography>
                    <Typography
                      variant='caption'
                      fontWeight={500}>
                      {
                        mode === 1
                        ? dayjs(update?.invoice_date)?.format("MMM DD, YYYY")
                        : mode === 2
                          ? dayjs(update?.created_at)?.format("MMM DD, YYYY")
                          : ""
                      }
                    </Typography>
                  </Box> */}
                  {
                    invoice
                    &&
                    <Box
                      className="flexCenter_Row"
                      gap={"5px"}>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        Invoice due date
                      </Typography>
                      <Typography
                        variant='caption'
                        fontWeight={500}>
                        {dayjs(formData?.InvoiceDueDate).format("MMM DD, YYYY")}
                      </Typography>
                    </Box>
                  }
                </Box>
                <LocalizationProvider 
                  sx={{ maxHeight: "100px" }} 
                  dateAdapter={AdapterDayjs}>
                  <DateCalendar 
                    minDate={dayjs()}
                    maxDate={formData.InvoiceDueDate ? dayjs(formData.InvoiceDueDate) : null}
                    shouldDisableDate={disableSpecificDates}
                    value={formData.Date}
                    onChange={(e) => {
                      setSelectedServiceId(null);
                      handleChange({
                        target: {
                          name: "Date",
                          value: e
                        }
                      })
                    }} 
                    />
                </LocalizationProvider>
                <Box  
                  width={"340px"}
                  mt="-30px"
                  mb="20px"
                  className="flexCenterCenterRow">
                  <Box
                    className="flexCenter_Row"
                    gap={"5px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}>
                      Debit date
                    </Typography>
                    <Typography
                      variant='caption'
                      fontWeight={500}>
                      {dayjs(formData?.Date).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                </Box>
                <FormControl>
                  <FormLabel id="create-payment-radio-group">
                    Choose payment speed
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="create-payment-radio-group"
                    name="radio-buttons-group"
                    value={selectedServiceId}
                    onChange={(e) => {
                      setSelectedServiceId(e.target.value);
                      let serviceTemp = servicesList.find(i => i.id+"" === e.target.value);
                      if(serviceTemp){
                        setSelectedService(serviceTemp);
                        handleChange({ target: {
                          name: "PaymentDate",
                          value: dayjs(formData.Date).add(serviceTemp?.delivery_method?.delivery_speed, 'day')
                        }});
                        setFeeAmount(serviceFees[servicesList.indexOf(serviceTemp)]);
                      }
                    }}>
                    {
                      servicesList.filter(i => {
                        if(i?.delivery_method?.delivery_method === "INSTANT_TRANSFER"){
                          if(dayjs(formData.Date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
                            return true;
                          }else {
                            return false;
                          }
                        }else {
                          return true;
                        }
                      }).map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.id}
                          disabled={item.delivery_method === "DOMESTIC_WIRE" || loadingServiceFeePrice}
                          control={<Radio />} 
                          label={
                            <Box
                              mt={"10px"}>
                              <Box className="flexCenter_Row">
                                <Typography
                                  variant='body2'>
                                  {handleDeliveryMethod(item?.delivery_method?.delivery_method) || <NA />}
                                </Typography>&nbsp;
                                {
                                  item?.delivery_method?.delivery_speed > 1
                                  &&
                                  <Chip
                                    size='small'
                                    sx={{ marginLeft: "5px" }}
                                    label={
                                      <>
                                        {item?.delivery_method?.delivery_speed}&nbsp;days
                                      </>
                                    }/>
                                }
                              </Box>
                              {
                                loadingServiceFeePrice
                                ?
                                <Skeleton
                                  variant="text" 
                                  />
                                :
                                <Typography
                                  variant='caption'
                                  color={"grey"}>
                                  Fee {handleShowAmount("USD", serviceFees[index])}
                                </Typography>
                              }
                              {/* <Typography variant='caption' color={"grey"}>Free</Typography> */}
                            </Box>
                          }
                          />
                      ))
                    }
                  </RadioGroup>
                </FormControl>
              </Box>
              <LoadingButton
                variant="contained"
                disabled={selectedServiceId === null || loadingServiceFeePrice}
                onClick={handleNextStep}
                >
                Continue
              </LoadingButton>
            </Box>
          </Container>
          : 
            step === 3
            // review & confirm screen
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Row"
                mt={"20px"}>
                <IconButton
                  onClick={handlePrevStep}>
                  <ArrowBackOutlinedIcon />
                </IconButton>
              </Box>
              <Box
                className="flex__Column"
                gap={"30px"}>
                <Typography
                  variant='h4'
                  textAlign={"center"}
                  mt={"20px"}>
                  Review & confirm
                </Typography> 
                <Box
                  className="flex__Column"
                  gap={"10px"}>
                  <Box
                    className="flex__Column">
                    <Typography
                      variant='body2'
                      mb={"8px"}
                      fontWeight={500}>
                      Payment amount
                    </Typography>
                    <Typography
                      ml={"20px"}
                      variant='h6'>
                      {handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    className="flex__Column">
                    <Typography
                      variant='body2'
                      mb={"8px"}
                      fontWeight={500}>Pay to</Typography>
                    <Box
                      ml={"20px"}
                      className="flexCenterSBRow">
                      <Box
                        className="flex__Column">
                        <Typography
                          variant='caption'
                          color={"grey"}>Business name</Typography>
                        <Typography
                          variant='subtitle1'>
                          {company.company_name}
                        </Typography>
                      </Box>
                      <Box
                        className="flex__Column">
                        <Typography
                          variant='caption'
                          color={"grey"}>Invoice #</Typography>
                        <Typography
                          variant='subtitle1'>
                          {(formData?.Invoice && formData?.Invoice !== "NA")
                            ? "#" + formData?.Invoice
                            : <i style={{ color: "grey" }}>No invoice #</i>}
                          </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    className="flex__Column">
                    <Typography
                      variant='body2'
                      mb={"8px"}
                      fontWeight={500}>Pay from</Typography>
                    <Box
                      ml={"20px"}>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        Bank account
                      </Typography>
                      {
                        selectedBankAccount
                        &&
                        <Typography
                          variant='subtitle1'>
                          Bank account (...{selectedBankAccount?.account_number?.slice(-4)})
                        </Typography>
                      }
                    </Box>
                    <Box
                      ml={"20px"}>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        Debit date
                      </Typography>
                      <Typography
                        variant='subtitle1'>
                        {dayjs(formData.Date).format("MMM DD, YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    className="flex__Column">
                    <Typography
                      variant='body2'
                      mb={"8px"}
                      fontWeight={500}>Business Receives</Typography>
                    <Box
                      ml={"20px"}>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        Bank transfer to
                      </Typography>
                      <Typography
                        variant='subtitle1'>
                        {company.company_name}'s primary account
                      </Typography>
                    </Box>
                    <Box
                      ml={"20px"}>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        Delivery date
                      </Typography>
                      <Typography
                        variant='subtitle1'>
                        {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  {/* <Box
                    className="flex__Column">
                    <Typography
                      variant='caption'>Memo to vendor</Typography>
                    <Typography
                      variant='subtitle1'>No memo added</Typography>
                  </Box>
                  <Divider /> */}
                  <Box
                    className="flex__Column">
                    <Typography
                      variant='body2'
                      mb={"8px"}
                      fontWeight={500}>Transaction fees</Typography>
                    <Box
                      ml={"20px"}
                      className="flexCenterSBRow">
                      <Typography
                        variant='subtitle1'>Total fee</Typography>
                      <Typography
                        variant='subtitle1'>
                        {handleShowAmount("USD", feeAmount)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={handlePay}
                  >
                  Confirm and schedule payment
                </LoadingButton>
              </Box>
            </Container>
            :
              step === 4
              // scheduled screen
              ?
              <Container
                maxWidth="sm">
                <Box
                  width={"100%"}
                  margin={"0px 20px"}
                  className="flex__Column"
                  gap={"30px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}
                    mt={"40px"}>
                    Payment scheduled
                  </Typography> 
                  <Box
                    className="flex__Column"
                    gap={"30px"}>
                    <Box
                      className="flex__Column">
                      <Typography
                        variant='body1'
                        fontWeight={500}>Payment summary</Typography>
                      <Typography
                        ml={"20px"}
                        variant='h6'>
                        {handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}
                      </Typography>
                    </Box>
                    <Box
                      className="flex__Column"
                      gap={"5px"}>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Pay to business
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          {company.company_name}
                        </Typography>
                      </Box>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Payment method
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          Business checking 
                        </Typography>
                      </Box>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Debit date
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          {dayjs(formData.Date).format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Delivery method
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          {handleDeliveryMethod(selectedService?.delivery_method?.delivery_method) || <NA />} transfer to&nbsp;
                          {company.company_name}'s primary account
                        </Typography>
                      </Box>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Delivery date
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                      <Box  
                        className="flexCenter_Row">
                        <Typography 
                          width={"50%"}
                          color={"grey"}>
                          Fees
                        </Typography>
                        <Typography 
                          width={"50%"}>
                          {handleShowAmount("USD", feeAmount)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <LoadingButton
                    variant="contained"
                    disabled={selectedServiceId === null}
                    onClick={() => {
                      navigate("/pay/payments");
                    }}>
                    Go to payments
                  </LoadingButton>
                </Box>
              </Container>
              :
              <></>
      }
    </Box>
  )
}

export default LoggedInFlow;