import React from 'react';

import {
  Box,
  Button,
  Modal, 
  Typography
} from '@mui/material';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import NA from '../../../../components/utils/NA';

import "./styles.scss";

const VendorBankAccountsModal = ({
  open, 
  setOpen, 
  title, 
  update,
  handleGet,
  handleVerify,
}) => {

//   {
//     "id": "3a095ef6-bd08-43ab-9c3b-1a1d16bd2b05",
//     "customer_party_id": "9b9522cd-10d2-470a-a21e-1b8f0ab87292",
//     "legal_name": "Party EEE",
//     "trading_name": "Party EEE",
//     "logo_url": "Santacruz",
//     "color": "",
//     "tax_id": "123123234",
//     "tax_id_type": "ein",
//     "incorporation_date": "2025-01-06",
//     "address": "Santacruz",
//     "city": "New York",
//     "state": "HI",
//     "country": "US",
//     "pincode": "22030",
//     "contact_name": "Michael Saylor",
//     "email": "mike@dynatech.com",
//     "domain": "dynatech.com",
//     "verification_level": "L2",
//     "entity_type": "Company",
//     "created_by": "23bf50c1-1779-4fdd-9e72-7d86e4c270cd",
//     "is_active": true,
//     "business_handle": "party-eee",
//     "createdAt": "2025-01-15T05:01:33.282Z",
//     "updatedAt": "2025-01-15T05:01:33.282Z",
//     "deletedAt": null,
//     "party_transaction_methods": [
//         {
//             "id": "572cbd19-438c-4bf0-b12f-423d783f2562",
//             "customer_account_id": "7f9b0fbe-17ac-41e6-81fb-48531f34830d",
//             "ref_party_id": "3a095ef6-bd08-43ab-9c3b-1a1d16bd2b05",
//             "transaction_method_id": "01c55e6f-c8b4-42d4-8914-aef088c14e75",
//             "is_primary_receiving": true,
//             "is_primary_paying": true,
//             "pan": "",
//             "expiry": "",
//             "account_holder_name": "James Miller",
//             "account_number": "1111222233330000",
//             "account_type": "checking",
//             "bank_name": "",
//             "routing_code_type_1": "ach",
//             "routing_code_value_1": "011401533",
//             "routing_code_type_2": "",
//             "routing_code_value_2": "",
//             "created_by": "23bf50c1-1779-4fdd-9e72-7d86e4c270cd",
//             "is_active": true,
//             "createdAt": "2025-01-15T05:02:49.678Z",
//             "updatedAt": "2025-01-15T05:02:49.678Z",
//             "deletedAt": null
//         },
//         {
//             "id": "afae13b0-99da-46de-86ef-b5840569a2f7",
//             "customer_account_id": "7da3c38e-2c6e-4071-8a66-795b939146e7",
//             "ref_party_id": "3a095ef6-bd08-43ab-9c3b-1a1d16bd2b05",
//             "transaction_method_id": "01c55e6f-c8b4-42d4-8914-aef088c14e75",
//             "is_primary_receiving": false,
//             "is_primary_paying": false,
//             "pan": "",
//             "expiry": "",
//             "account_holder_name": "James Miller",
//             "account_number": "1111222233331111",
//             "account_type": "savings",
//             "bank_name": "",
//             "routing_code_type_1": "ach",
//             "routing_code_value_1": "011401533",
//             "routing_code_type_2": "",
//             "routing_code_value_2": "",
//             "created_by": "23bf50c1-1779-4fdd-9e72-7d86e4c270cd",
//             "is_active": true,
//             "createdAt": "2025-01-15T05:04:18.037Z",
//             "updatedAt": "2025-01-15T05:04:18.037Z",
//             "deletedAt": null
//         }
//     ]
// }

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddBankAccount = (bank) => {
    handleVerify(bank)
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box minWidth={"600px"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "20px 20px 30px 20px"}}>
            <Typography variant='h6' mb={"20px"}>
              {title}&nbsp;for&nbsp;{update?.legal_name}
            </Typography>

            <Box
              className="flex__Column"
              width={"100%"}
              gap={"15px"}
              sx={{ 
                padding: "10px",
                overflow: "auto",
              }}>
              {
                update?.party_bank_accounts?.map((item, index) => (
                  <Box
                    className="flexCenter_Row"
                    sx={{
                      gap: "10px",
                      borderRadius: "16px",
                      padding: "15px",
                      width: "100%",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    key={index}>
                    <AccountBalanceOutlinedIcon 
                      fontSize="large"
                      />
                    <Box
                      width={"100%"}>
                      <Typography variant='subtitle2'>
                        {item?.account_type || <NA />}
                        &nbsp;-&nbsp;
                        {item?.account_number?.slice(-4) || <NA />}
                      </Typography>
                    </Box>
                    <Button
                      variant='contained'
                      onClick={() => handleAddBankAccount(item)}>
                      Add
                    </Button>
                  </Box>
                ))
              }
            </Box>

            <Box>



            </Box>

          </Box>
          {/* <Divider /> */}
          <Box className="flexCenterFERow"
            sx={{ gap: "20px", margin: "15px"}}>
            <Button 
              variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button 
              variant='contained' 
              color={'error'}
              onClick={onConfirm}>
              Confirm
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default VendorBankAccountsModal;