import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { 
  Box,
	Button,
	Container, 
	LinearProgress, 
	Typography 
} from '@mui/material';

import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import useSelectedCompany from '../components/hooks/useSelectedCompany';
import ErrorHandler from '../components/errorHandler/ErrorHandler';
import useCompanyRole from '../components/hooks/useCompanyRole';
import Navigation from "../components/navigation/Navigation";
import TimeoutModal from '../components/modals/TimeoutModal';
import ImageViewer from '../components/utils/ImageViewer';
import packageJson from "../../package.json"; 

import { adminTheme } from "../theme/AdminTheme";
import { ThemeProvider } from '@mui/material/styles';

import { ReactComponent as Logo }  from "../assets/icons/logo-blue-dark2.svg";

import { 
  GetCompanyInfo
} from '../redux/actions/dashboard/companyRoleActions';

import { 
  CLEAR_COMPANY_ROLE_DASHBOARD, 
  LOG_OUT 
} from '../redux/actionTypes';

//routes
import AuthPage from '../views/authPage/AuthPage';
import RegisterPage from '../views/authPage/RegisterPage';
import ForgotPasswordPage from '../views/authPage/ForgotPasswordPage';
import HomePage from '../views/homePage/HomePage';

import CompaniesPage from '../views/dashboard/companiesPage/CompaniesPage';
import ProfilePage from '../views/dashboard/profilePage/ProfilePage';
import AddCompanyBankAccountPage from '../views/dashboard/companiesPage/AddCompanyBankAccountPage';
import AddSubscriptionPage from '../views/dashboard/companiesPage/AddSubscriptionPage';

// import DashboardIndex from '../views/dashboard/DashboardIndex';
import PayIndex from '../views/pay/PayIndex';
import GetPaidIndex from '../views/receive/GetPaidIndex';
// import ExpenseIndex from '../views/expense/ExpenseIndex';
// import WalletIndex from '../views/wallet/WalletIndex';

import MakePaymentPage from '../views/makePaymentPage/MakePaymentPage';

// settings routes
import MainIndexSET from '../views/settings/main/MainIndex';
import PayIndexSET from '../views/settings/pay/PayIndex';
import ReceiveIndexSET from '../views/settings/receive/ReceiveIndex';
// import ExpenseIndexSET from '../views/settings/expense/ExpenseIndex';
// import WalletIndexSET from '../views/settings/wallet/WalletIndex';
import BillingIndexSET from '../views/settings/billing/BillingIndex';
import InternationalIndexSET from '../views/settings/international/InternationalIndex';
import AccountingIndexSET from '../views/settings/accounting/AccountingIndex';



const MainRouter = () => {
  const companyRole = useCompanyRole();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);

  const onAction = (event, idleTimer) => {
    if (idleTimer.isPrompted()) {
      idleTimer.activate()
    }
  }
  
  const onActive = (event, idleTimer) => {
    if (idleTimer.isPrompted()) {
    }
  }
  
  const onPrompt = () => {
    console.log("onPrompt")
    // setTimeoutModalOpen(true);
  }
  
  const onIdle = () => {
    console.log("onIdle")
  }
  
  useIdleTimer({
    timeout: 1000 * 15 * 60,
    promptBeforeIdle: 1000 * 10 * 60,
    onAction,
    onActive,
    onPrompt,
    onIdle
  });

  return (
    <Router>

      <TimeoutModal
        open={timeoutModalOpen}
        setOpen={setTimeoutModalOpen}
        />

      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path='/auth' element={<AuthPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path="/switch" element={<CompanySwitchScreen />} />

          <Route path='/' element={<Navigation />}>
            <Route path='' element={<HomePage />} />

            {/* dashboard */}
            <Route path='dashboard' element={<CompaniesPage />} />
            <Route path='profile' element={<ProfilePage />} />

            {/* pay */}
            <Route path='pay' element={<PayIndex />} />
            <Route path='pay/:child' element={<PayIndex />} />

            {/* get paid */}
            <Route path='get-paid' element={<GetPaidIndex />} />
            <Route path='get-paid/:child' element={<GetPaidIndex />} />

            {/* expense */}
            {/* <Route path='expense' element={<ExpenseIndex />} />
            <Route path='expense/:child' element={<ExpenseIndex />} /> */}

            {/* wallet */}
            {/* <Route path='wallet' element={<WalletIndex />} />
            <Route path='wallet/:child' element={<WalletIndex />} /> */}

            {/* initial company setup */}
            <Route path='add-bank-account' element={<AddCompanyBankAccountPage />} />
            <Route path='add-subscription' element={<AddSubscriptionPage />} />

            {/* settings */}
            {
              companyRole === "Admin"
              &&
              <Route path='settings'>
                {/* main */}
                <Route path='' element={<ThemeProvider theme={adminTheme}><MainIndexSET /></ThemeProvider>} />
                <Route path=':child' element={<ThemeProvider theme={adminTheme}><MainIndexSET /></ThemeProvider>} />

                {/* pay */}
                <Route path='pay' element={<ThemeProvider theme={adminTheme}><PayIndexSET /></ThemeProvider>} />
                <Route path='pay/:child' element={<ThemeProvider theme={adminTheme}><PayIndexSET /></ThemeProvider>} />

                {/* get paid */}
                <Route path='receive' element={<ThemeProvider theme={adminTheme}><ReceiveIndexSET /></ThemeProvider>} />
                <Route path='receive/:child' element={<ThemeProvider theme={adminTheme}><ReceiveIndexSET /></ThemeProvider>} />

                {/* expense */}
                {/* <Route path='expense' element={<ThemeProvider theme={adminTheme}><ExpenseIndexSET /></ThemeProvider>} />
                <Route path='expense/:child' element={<ThemeProvider theme={adminTheme}><ExpenseIndexSET /></ThemeProvider>} /> */}

                {/* wallet */}
                {/* <Route path='wallet' element={<ThemeProvider theme={adminTheme}><WalletIndexSET /></ThemeProvider>} />
                <Route path='wallet/:child' element={<ThemeProvider theme={adminTheme}><WalletIndexSET /></ThemeProvider>} /> */}

                {/* international */}
                <Route path='international' element={<ThemeProvider theme={adminTheme}><InternationalIndexSET /></ThemeProvider>} />
                <Route path='international/:child' element={<ThemeProvider theme={adminTheme}><InternationalIndexSET /></ThemeProvider>} />

                {/* accounting */}
                <Route path='accounting' element={<ThemeProvider theme={adminTheme}><AccountingIndexSET /></ThemeProvider>} />
                <Route path='accounting/:child' element={<ThemeProvider theme={adminTheme}><AccountingIndexSET /></ThemeProvider>} />

                {/* billing */}
                <Route path='billing' element={<ThemeProvider theme={adminTheme}><BillingIndexSET /></ThemeProvider>} />
                <Route path='billing/:child' element={<ThemeProvider theme={adminTheme}><BillingIndexSET /></ThemeProvider>} />

              </Route>
            }
          </Route>

        </Route>
        <Route path="/version" element={<AppVersion />} />
        <Route path='/makepayment' element={<MakePaymentPage />} />
        <Route path='/makepayment/:id' element={<MakePaymentPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <ErrorHandler />
      <ImageViewer />
    </Router>
  )
}

export default MainRouter;

// 404 page
const NotFoundPage = () => {
  const navigate = useNavigate();
  return(
		<Container maxWidth="lg">
      <Box sx={{ height: "100vh" }} 
        gap={"40px"}
        className="flexCenterCenterColumn">
			  <Typography variant='h4'>404 - Page not found</Typography>
        <Button 
          variant='contained'
          onClick={() => navigate("/dashboard")}>
          Go Home
        </Button>
      </Box>
		</Container>
  )
};

// Company switch screen
const CompanySwitchScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(location)

    try {
      // setTimeout(() => {
        dispatch({ type: CLEAR_COMPANY_ROLE_DASHBOARD });
        dispatch(GetCompanyInfo(companyId, setLoading)).then(({ res, statusCode }) => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            if(statusCode === 200){
              if(res.data.company_bank_account_exists === false){
                navigate("/add-bank-account");
              } else if (res.data.company_has_subs === false){
                navigate("/add-subscription");
              } else {
                navigate(location.state, { replace: true });
              }
            } else {
    
            }
          }, 1000);
        })
      // }, 200);
    } catch (err){
      dispatch({ type: LOG_OUT });
    }
    
    // const navTimer = setTimeout(() => {
    //   navigate(location.state, { replace: true });
    // }, 500);
    // const valueTimer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 3));
    // }, 10); // 20 milliseconds interval will take 2000 ms to reach 100

    return () => {
      // clearInterval(navTimer);
      // clearInterval(valueTimer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      className="flexCenterCenterColumn"
      gap="40px"
      width={"100%"}
      height={"100vh"}>

      <Box
        maxWidth={'350px'}
        height={"100px"}
        className="animate__faster animate__animated animate__zoomIn ">
        <Logo 
          style={{ width: "350px", height: "100px" }}/>
      </Box>

      <LinearProgress 
        sx={{ width: "320px" }}
        // variant="determinate"
        // value={progress}
        value={loading}
        />

      <Typography
        color="grey"
        variant='subtitle1'>
        Switching company
      </Typography>

    </Box>
  )
};

const AppVersion = () => {
  return(
    <Box
      sx={{ height: "100vh" }}>
      <Typography
        color={"black"}>
        App version: {packageJson.version}
      </Typography>
    </Box>
  )
};