const DateFormatter = (date, timeZone) => {
  let usEasternTimeDate;
  if (timeZone === "UTC"){
    const utcTime = new Date(date); // Example UTC time
    const usEasternTime = utcTime.toLocaleString("en-US", { timeZone: "America/New_York" });
    usEasternTimeDate = new Date(usEasternTime);
  } else {
    usEasternTimeDate = new Date(date);
  }

  return (
    date 
    ? 
      usEasternTimeDate.toDateString().slice(4, 10) + ", " + usEasternTimeDate.getFullYear()
    :
      "NA"
  )
}

export default DateFormatter;