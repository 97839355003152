import { useSelector } from 'react-redux';

const useCompanyAllowedForInternationlPayments = () => {
    const state = useSelector(state => state.dashboard);

    return (
        state.allowedForInternationalPayments
    )
}

export default useCompanyAllowedForInternationlPayments;