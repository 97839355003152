import React from 'react';
import { toast } from 'react-toastify';

import {
  Box, 
  Button, 
  Typography
} from '@mui/material';

import useCompanyAllowedForInternationlPayments from '../../../../components/hooks/useCompanyAllowedForInternationlPayments';

import "./styles.scss";

const OnboardingPage = () => {
  const companyAllowedForInternationalPayments = useCompanyAllowedForInternationlPayments();

  const handleStartOnboarding = () => {
    let url = "";
    if(process.env.REACT_APP_ENV === "alpha"){
      url = "https://onboarding-demo.paydirect.io/lusid/forms/corporate";
    } else if (process.env.REACT_APP_ENV === "production"){
      url = "https://onboarding.paydirect.io/lusid/forms/corporate";
    } else {
      toast.error("Something went wrong");
      return;
    };

    window.open(url, "_blank", "noopener,noreferrer");
  }

  return (  
    <Box
      className="flexCenterCenterColumn"
      height={"calc(100vh - 300px)"}>
      {
        companyAllowedForInternationalPayments === true
        ?
        <Box>
          <Typography
            variant='subtitle1'
            color={"#434343"}>
            Already have access for international payments.
          </Typography>
        </Box>
        :
        <Box
          className="flexCenterCenterColumn"
          gap={"20px"}>
          <Typography
            variant='subtitle1'
            color={"#434343"}>
            Finish onboarding in order to use international payments.
          </Typography>
          <Button
            variant='contained'
            onClick={handleStartOnboarding}>
            Let's start
          </Button>
        </Box>
      }
    </Box>
  )
}

export default OnboardingPage;