import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Skeleton, 
  Tooltip, 
  Typography,
} from '@mui/material';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import NA from '../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY 
} from '../../../redux/actionTypes';
import { 
  CreateVerificationCompanyBankAccount,
  GetBankAccountTypes,
  GetBanks,
  GetCompanyBankAccounts 
} from '../../../redux/actions/pay/companyBankAccountsActions';

import AddCompanyBankAccountModal from './AddCompanyBankAccountModal';

import "./styles.scss";

const AddCompanyBankAccountPage = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const other = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const [addCompanyBankAccountModal, setAddCompanyBankAccountModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [companyBankAccountsList, setCompanyBankAccountsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [companyBankAccountsTotal, setCompanyBankAccountsTotal] = useState(0);

  const [selectedCompany, setSelectedCompany] = useState({});

  const [verifyId, setVerifyId] = useState(null);
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    dispatch({ type: CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY });
    handleGetCompanyBankAccounts();
    dispatch(GetBankAccountTypes(companyId, 1, 50, setLoading2));
    dispatch(GetBanks(companyId, 1, 500, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(state.companyBankAccountsList.records || []);
      setCompanyBankAccountsTotal(state.companyBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  useEffect(() => {
    try {
      setSelectedCompany(other.selectedCompany || {});
    } catch (err) {}
  }, [other]);

  const formatCompanyBankAccountsList = (list) => {
    setCompanyBankAccountsList(list);
  };

  async function handleGetCompanyBankAccounts(customLoading){
    dispatch(GetCompanyBankAccounts(companyId, 1, 100, customLoading ? customLoading : setLoading));
  };

  function handleVerifyBankAccount(id){
    setVerifyId(id);
    let obj = {
      "bank_account_id": id
    };
    dispatch(CreateVerificationCompanyBankAccount(companyId, obj, setVerifying));
  };

  function getBankStatusVerifyComment(status){
    switch(status){
      case "valid": return "- Bank account is open and valid";
      case "closed": return "- Bank account is closed";
      case "invalid": return "- Account information is invalid";
      case "failed": return "- The verification request has failed";
      case "pending": return "";
      case null: return "";

      default: return "Invalid status";
    }
  };

  function getBankControlVerifyComment(status){
    switch(status){
      case "valid": return "";
      case "pending": return "";
      case "canceled": return "- The maximum number of attempts has been reached or it has been 5 days since the request was sent to the receiving bank";
      case null: return "";

      default: return "Invalid status";
    }
  };

  return (
    <Box>

      <AddCompanyBankAccountModal 
        open={addCompanyBankAccountModal}
        setOpen={setAddCompanyBankAccountModal}
        title={modalTitle}
        update={update}
        handleGet={handleGetCompanyBankAccounts}
        />
                 
      <ModalTopBar
        onBack={() => navigate("/dashboard")}
        title={selectedCompany.LegalName || ""} 
        />

      <Box 
        className="companybank_top">
        <Box
          className="flexCenterSBRow">
          <Typography
            variant='h6'>
            Bank Account
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Add your bank account to continue");
              setAddCompanyBankAccountModal(!addCompanyBankAccountModal);
            }}>
            Add Bank account
          </Button>
        </Box>

        {
          loading 
          ?
          <Skeleton 
            variant='rectangular'
            sx={{ height: "90px", borderRadius: "16px" }}
            />
          :
          companyBankAccountsList.length === 0
          ?
          <Box
            className="flexCenterCenterRow"
            height={"90px"}>
            <NA 
              label={"No bank account added"}/>
          </Box>
          :
            companyBankAccountsList.map((bank, bankIndex) => (
              <Box
                className="companybank_top-card"
                key={bankIndex}>
                <Box
                  className="flexCenter_Row"
                  gap={"20px"}>
                  <AccountBalanceOutlinedIcon 
                    fontSize="large"
                    />
                  <Box
                    sx={{ marginRight: "auto" }}>
                    <Typography variant='subtitle2'>
                      {bank?.account_type_id?.type || <NA />}
                      &nbsp;-&nbsp;
                      {bank.account_number?.slice(-5) || <NA />}</Typography>
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      {
                        bank.is_default === true
                        &&
                        <Chip
                          color='warning'
                          size='small'
                          label={'Primary'} 
                          />
                      }
                      {
                        bank.verified === false && bank.verification_pending === false
                        &&
                        <Chip
                          color='error'
                          size='small'
                          label={'Unverified'} 
                          />
                      }
                    </Box>
                  </Box>

                  {
                    bank.verification_pending === true
                    ?
                    <Tooltip>
                      <Chip
                        color='warning'
                        label={"Verification pending"} 
                        />
                    </Tooltip>
                    :
                    <Box
                      className="flexCenter_Row"
                      gap={"5px"}>
                      {
                        bank.verified === false
                        &&
                          (bank.id === verifyId && verifying
                          ?
                          <CircularProgress 
                            size={28}
                            />
                          :
                          <Chip
                            color='info'
                            label={"Verify"} 
                            clickable={true}
                            onClick={() => {
                              handleVerifyBankAccount(bank.id);
                            }}
                            />)
                      }
                      <IconButton
                        onClick={(e) => {
                          // setBankAccountsMenuAnchor(e.currentTarget);
                          // setBankAccountsMenuOpen(true);
                          // setDeleteId(row.id);
                          setUpdate(bank);
                          setModalTitle(bank.account_number || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </Box>
                  }
                </Box>
                {
                  bank.verification_pending === true
                  &&
                  <Box
                    pt={"20px"}
                    pl={"55px"}
                    className="flex__Column"
                    gap="10px">
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      {
                        bank.verification_status === "valid"
                        ?
                        <CheckCircleOutlinedIcon 
                          color='success'
                          fontSize='small'
                          />
                        :
                          ["closed", "invalid", "failed"].includes(bank.verification_status)
                          ?
                          <CancelOutlinedIcon 
                            color='error'
                            fontSize='small'
                            />
                          :
                            bank.verification_status === "pending" || bank.verification_status === null
                            ?
                            <CircularProgress 
                              size={17}
                              />
                            :
                            <Box 
                              width={"20px"}
                              height={"20px"} />
                      }
                      <Typography
                        fontWeight={500}>
                        Account status
                      </Typography>
                      <Typography
                        ml={"-5px"}>
                        {getBankStatusVerifyComment(bank.verification_status)}
                      </Typography>
                    </Box>
                    <Box
                      className="flexFS_Row"
                      gap={"10px"}>
                      {
                        bank.verification_status === "valid"
                        ?
                          bank.control_status === "valid"
                          ?
                          <CheckCircleOutlinedIcon 
                            color='success'
                            fontSize='small'
                            />
                          :
                            bank.control_status === "canceled"
                            ?
                            <CancelOutlinedIcon 
                              color='error'
                              fontSize='small'
                              />
                            :
                              bank.control_status_applied === false
                              ?
                              <Box 
                                width={"20px"}
                                height={"20px"} />
                              :
                                bank.control_status === "pending" || bank.control_status === null
                                ?
                                <CircularProgress 
                                  size={17}
                                  sx={{ margin: "1.5px" }}
                                  />
                                :
                                <Box 
                                  width={"20px"}
                                  height={"20px"} />
                          :
                          <Box 
                            width={"20px"}
                            height={"20px"} />
                      }
                      <Typography
                        fontWeight={500}
                        color={bank.verification_status === "valid" ? "black" : "grey"}>
                        Account access
                      </Typography>
                      <Typography
                        ml={"-5px"}>
                        {getBankControlVerifyComment(bank.control_status)}
                      </Typography>
                      {
                        (bank.control_status_applied === false 
                          && bank.verification_status === "valid" 
                          && bank.control_status !== "canceled")
                        &&
                        <Tooltip>
                          <Chip
                            color='info'
                            label={"Submit code"} 
                            clickable={true}
                            onClick={() => {
                              setUpdate({
                                defaultMode: 3, 
                                bank_account_id: bank.id
                              });
                              setModalTitle("Enter statement code");
                              setAddCompanyBankAccountModal(!addCompanyBankAccountModal);
                            }}
                            />
                        </Tooltip>
                      }
                    </Box>
                  </Box>
                }
              </Box>
            ))
        }


        {/* <Link
          onClick={() => {
            setUpdate({
              defaultMode: 1
            });
            setModalTitle("Add Bank account")
            // setBankAccountsModalOpen(!bankAccountsModalOpen);
          }}>
          + Add another Bank Account
        </Link> */}
      </Box>
       
    </Box>
  )
}

export default AddCompanyBankAccountPage;