import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import TabsPanel from '../../../components/tabs/TabsPanel';
import OverviewPage from './overviewPage/OverviewPage';
import PayPage from './payPage/PayPage';
import ReceivePage from './receivePage/ReceivePage';
import ExpensePage from './expensePage/ExpensePage';
import InternationalPage from './internationalPage/InternationalPage';

const BillingIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("billingTabSET"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "overview": 
          localStorage.setItem("billingTabSET", "1"); 
          setCurrentTab("1"); 
          break;
        case "pay": 
          localStorage.setItem("billingTabSET", "2"); 
          setCurrentTab("2"); 
          break;
        case "receive": 
          localStorage.setItem("billingTabSET", "3"); 
          setCurrentTab("3"); 
          break;
        case "expense": 
          localStorage.setItem("billingTabSET", "4"); 
          setCurrentTab("4"); 
          break;
        case "international": 
          localStorage.setItem("billingTabSET", "5"); 
          setCurrentTab("5"); 
          break;

        default: {
          localStorage.setItem("billingTabSET", "1");
          setCurrentTab("1");
          navigate("/settings");
        }
      }
      navigate("/settings")
    }else{
      if(!localStorage.getItem("billingTabSET")){
        localStorage.setItem("billingTabSET", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Billing"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("billingTabSET", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Subscription", path: "/overview" },
            { value: "2", label: "Pay", path: "/pay" },
            { value: "3", label: "Get Paid", path: "/receive" },
            // { value: "4", label: "Expense management", path: "/expense" },
            { value: "5", label: "International", path: "/international" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <OverviewPage />
          :
            currentTab === "2"
            ?
              <PayPage />
              :
                currentTab === "3"
                ?
                  <ReceivePage />
                  :
                    currentTab === "4"
                    ?
                      <ExpensePage />
                      :
                        currentTab === "5"
                        ?
                          <InternationalPage />
                          :
                          <></>
      }
    </Box>
  )
}

export default BillingIndex;