import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import "./styles.scss";

const Data = {
  ach_routing: "",
  account_number: ""
};

const VerifyBankAccountModal = ({
  open, 
  setOpen, 
  title, 
  update,
  handleGet,
  handleCheckDetails,
}) => {
  const [formData, setFormData] = useState(Data);

  useEffect(() => {
    if(open) {
      setFormData(Data); 
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data); 
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleCheckBankDetails = () => {
    handleCheckDetails(formData);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box minWidth={"600px"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "20px 20px 30px 20px"}}>
            <Typography variant='h6' mb={"20px"}>
              {title}&nbsp;for&nbsp;{update?.legal_name}
            </Typography>

            <Box
              width={"100%"}
              className="flexCenter_Row"
              gap="15px">
              <TextField
                label="ACH routing"
                fullWidth
                name="ach_routing"
                value={formData.ach_routing}
                onChange={handleChange}
                inputProps={{
                  maxLength: 128
                }}
                />
              <TextField 
                label="Account number"
                fullWidth
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                inputProps={{
                  maxLength: 128
                }}
                />
            </Box>

          </Box>
          {/* <Divider /> */}
          <Box className="flexCenterFERow"
            sx={{ gap: "20px", margin: "15px"}}>
            <Button 
              variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              variant='contained' 
              color={'error'}
              onClick={handleCheckBankDetails}>
              Verify
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default VerifyBankAccountModal;