import axios from "axios";
import { LOG_OUT } from "../redux/actionTypes";
import { store } from "../redux/store";

export const IdentityAPI = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_MS_BASE_URL,
});

export const CoreAPI = axios.create({
  baseURL: process.env.REACT_APP_CORE_MS_BASE_URL,
});


// IDENTITY - REQUEST INTERCEPTOR
IdentityAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))}`;
  }
  return req;
});

// IDENTITY - RESPONSE INTERCEPTOR
IdentityAPI.interceptors.response.use((response) => { 
    return response 
  },
  async (error) => {


    // TOKEN REFRESH LOGIC
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loop of retries

      try {
        // Attempt to refresh the token
        const { data, status } = await refreshToken(
          { refresh_token: JSON.parse(localStorage.getItem("refresh_token")) },
          handleRequestCanceller()
        );

        console.log("interceptors", data, status)

        if(status === 200){
          // store it in local storage
          localStorage.setItem("user", JSON.stringify(data.data.access_token));
        }
        // Set the new access token in headers and retry the original request
        IdentityAPI.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${data.data.access_token}`;

        return IdentityAPI(originalRequest); // Retry the original request with new token
      } catch (refreshError) {
        console.log("refreshError", refreshError)
        if(refreshError.message !== "REQUEST_CANCELLED") {
          store.dispatch({ type: LOG_OUT });
        }
        return Promise.reject(refreshError);
      }
    }

    console.log("refreshError 2", error)
    return Promise.reject(error);
  }
);

// CORE - REQUEST INTERCEPTOR
CoreAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))}`;
  }
  return req;
});

// CORE - RESPONSE INTERCEPTOR
CoreAPI.interceptors.response.use((response) => { 
  return response 
},
async (error) => {

  // TOKEN REFRESH LOGIC
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true; // Prevent infinite loop of retries

    try {
      // Attempt to refresh the token
      const { data, status } = await refreshToken(
        { refresh_token: JSON.parse(localStorage.getItem("refresh_token")) },
        handleRequestCanceller()
      );

      console.log("interceptors", data, status)

      if(status === 200){
        // store it in local storage
        localStorage.setItem("user", JSON.stringify(data.data.access_token));
      }
      // Set the new access token in headers and retry the original request
      CoreAPI.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`;
      originalRequest.headers['Authorization'] = `Bearer ${data.data.access_token}`;

      return CoreAPI(originalRequest); // Retry the original request with new token
    } catch (refreshError) {
      console.log("refreshError", refreshError)
      if(refreshError.message !== "REQUEST_CANCELLED") {
        store.dispatch({ type: LOG_OUT });
      }
      return Promise.reject(refreshError);
    }
  }

  console.log("refreshError 2", error)
  return Promise.reject(error);
}
);


// REQUEST CANCELLER
let cancelToken
const handleRequestCanceller = (cancellable = true) => {
  if (!cancellable) {
    return;
  }
  
  if (cancelToken) {
    cancelToken.cancel('REQUEST_CANCELLED');
  }

  cancelToken = axios.CancelToken.source();

  return {
    cancelToken: cancelToken.token,
  }
};

// ======================== Auth ===========================
export const login = (form) =>  IdentityAPI.post(`web/v1/auth/login`, form);
export const register = (form) => IdentityAPI.post(`web/v1/auth/register`, form);
export const forgotPassword = () => IdentityAPI.post(``);
export const refreshToken = (form) => IdentityAPI.post(`web/v1/auth/refresh-token`, form, handleRequestCanceller());

export const sendRegisterOtp = (form) => IdentityAPI.post(`web/v1/auth/otp-send`, form);
export const resendRegisterOtp = (form) => IdentityAPI.post(`web/v1/auth/otp-resend`, form);
export const verifyRegisterOtp = (form) => IdentityAPI.post(`web/v1/auth/otp-verify`, form);

// ======================== Auth - Guest ====================
export const authenicateGuest = (form) => IdentityAPI.post(`web/v1/guest/authenicate`, form);

export const sendGuestOtp = (form) => IdentityAPI.post(`web/v1/guest/otp-send`, form);
export const resendGuestOtp = (form) => IdentityAPI.post(`web/v1/guest/otp-resend`, form);
export const verifyGuestOtp = (form) => IdentityAPI.post(`web/v1/guest/otp-verify`, form);

export const getGuestBasicInfo = () => IdentityAPI.get(`web/v1/guest/info`);
export const createCompanyForGuest = (form) => IdentityAPI.post(`web/v1/guest/info`, form);


// ======================== Dashboard =======================
export const getCompanies = (page, limit, query) => IdentityAPI.get(`web/v1/companies?page=${page}&limit=${limit}${query}`);
export const getCompany = (id) => IdentityAPI.get(`web/v1/companies/${id}`);
export const createCompany = (form) => IdentityAPI.post(`web/v1/companies`, form);
export const updateCompany = (id, form) => IdentityAPI.put(`web/v1/companies/${id}`, form);
export const deleteCompany = (id)  => IdentityAPI.delete(`companies/v1/${id}`);

export const getMyProfile = () => IdentityAPI.get(`web/v1/employees/my-profile`);
export const updateMyProfile = (form) => IdentityAPI.put(`web/v1/employees/my-profile`, form);

export const getUserBankAccount = () => CoreAPI.get(`web/v1/account/user-bank-accounts`);
export const createUserBankAccount = (form) => CoreAPI.post(`web/v1/account/user-bank-accounts`, form);
export const deleteUserBankAccount = (id) => CoreAPI.delete(`web/v1/account/user-bank-accounts/${id}`);
export const createVerificationUserBankAccount = (form) => CoreAPI.post(`web/v1/account/user-bank-accounts/verification`, form);
export const verifyUserBankAccount = (form) => CoreAPI.post(`web/v1/account/user-bank-accounts/verify`, form);

export const getCompanyInfo = (companyId) => IdentityAPI.get(`web/v1/employees/company-info?c_id=${companyId}`);

export const getCompanyAllowedForInternationalPayments = (companyId) => CoreAPI.get(`web/v1/account/company-allowed-for-international-payments/${companyId}?c_id=${companyId}`);


// ======================== Main =======================
export const getBranches = (companyId, page, limit) => IdentityAPI.get(`web/v1/branches?c_id=${companyId}&page=${page}&limit=${limit}`);
export const createBranch = (companyId, form) => IdentityAPI.post(`web/v1/branches?c_id=${companyId}`, form);
export const updateBranch = (companyId, id, form) => IdentityAPI.put(`web/v1/branches/${id}?c_id=${companyId}`, form);
export const deleteBranch = (companyId, id)  => IdentityAPI.delete(`web/v1/branches/${id}?c_id=${companyId}`);

export const getEmployees = (companyId, page, limit) => IdentityAPI.get(`web/v1/employees/?c_id=${companyId}&page=${page}&limit=${limit}`);
export const createEmployee = (companyId, form) => IdentityAPI.post(`web/v1/employees/?c_id=${companyId}`, form);
export const updateEmployee = (companyId, id, form) => IdentityAPI.put(`web/v1/employees/${id}/?c_id=${companyId}`, form);
export const deleteEmployee = (companyId, id)  => IdentityAPI.delete(`web/v1/employees/${id}/?c_id=${companyId}`);

export const getEmployeesAllowedForInternationalPayments = (companyId, form) => CoreAPI.post(`web/v1/account/employees-allowed-for-international-payments/${companyId}?c_id=${companyId}`, form);

export const getSubscriptions = (page, limit, query) => IdentityAPI.get(`web/v1/subscriptions?page=${page}&limit=${limit}${query}`);
export const addSubscription = (companyId, form) => IdentityAPI.put(`web/v1/companies/add-subscription?c_id=${companyId}`, form);
export const getMySubscription = (companyId) => IdentityAPI.get(`web/v1/subscriptions/my-plan?c_id=${companyId}`);
export const getPlanAddons = (page, limit, query) => IdentityAPI.get(`web/v1/plan-addons?page=${page}&limit=${limit}${query}`);


// ======================== UNSORTED =======================
export const associatedCompanies = () => IdentityAPI.get(`web/v1/users/associated-companies`);

export const getBankAccounts = (companyId) => CoreAPI.get(`v1/bank-accounts/?c_id=${companyId}`);

export const getRecurringBills = () => CoreAPI.get(`invoice/v1/recurring-bill/`);
export const getRecurringBill = (id) => CoreAPI.get(`invoice/v1/recurring-bill/${id}/`);
export const createRecurringBill = (form) => CoreAPI.post(`invoice/v1/recurring-bill/`, form);

export const getPaymentFees = (companyId) => CoreAPI.get(`invoice/v1/pmt-fees/?c_id=${companyId}`);

export const getDeliverySpeed = (companyId, id) => CoreAPI.get(`invoice/v1/delivery-speed/?c_id=${companyId}&method_id=${id}`);

export const getSuppliers = (companyId, page, limit) => CoreAPI.get(`supplier/v1/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createSupplier = (companyId, form) => CoreAPI.post(`supplier/v1/?c_id=${companyId}`, form);
export const updateSupplier = (companyId, id, form) => CoreAPI.put(`supplier/v1/${id}/?c_id=${companyId}`, form);
export const deleteSupplier = (companyId, id)  => CoreAPI.delete(`supplier/v1/${id}/?c_id=${companyId}`);

export const getWallets = (page, limit) => CoreAPI.get(`spend-wallet/wallet/v1/?page=${page}&page_size=${limit}`);
export const createWallet = (form) => CoreAPI.post(`spend-wallet/wallet/v1`, form);
export const updateWallet = (id, form) => CoreAPI.put(`spend-wallet/wallet/v1/${id}`, form);
export const deleteWallet = (id)  => CoreAPI.delete(`spend-wallet/wallet/v1/${id}`);

export const getVirtualCards = (page, limit) => CoreAPI.get(`my-card/v1/?page=${page}&page_size=${limit}`);
export const createVirtualCard = (form) => CoreAPI.post(`my-card/v1`, form);
export const updateVirtualCard = (id, form) => CoreAPI.put(`my-card/v1/${id}`, form);
export const deleteVirtualCard = (id)  => CoreAPI.delete(`my-card/v1/${id}`);


// ======================== Analytics =======================
export const getBillsStats = (companyId) => CoreAPI.get(`web/v1/pay/bill-stats-by-days?c_id=${companyId}`);
export const getExpenseStats = (companyId) => CoreAPI.get(`web/v1/expense/expense-stats-by-category?c_id=${companyId}`);
export const getInvoiceStats = (companyId) => CoreAPI.get(`web/v1/receive/invoice-stats-by-days?c_id=${companyId}`);


// ======================== Accounting ======================
export const getAccountTypes = (companyId, page, limit, query) => CoreAPI.get(`web/v1/accounting/account-type?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);

export const getChartOfAccounts = (companyId, page, limit, query) => CoreAPI.get(`web/v1/accounting/chart-of-accounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createChartOfAccount = (companyId, form) => CoreAPI.post(`web/v1/accounting/chart-of-accounts?c_id=${companyId}`, form);
export const updateChartOfAccount = (companyId, id, form) => CoreAPI.put(`web/v1/accounting/chart-of-accounts/${id}?c_id=${companyId}`, form);

export const getSalesTaxes = (companyId, page, limit, isHelper, query) => CoreAPI.get(`web/v1/accounting/sales-taxes?c_id=${companyId}&page=${page}&page_size=${limit}&is_helper=${isHelper}${query}`);
export const createSalesTax = (companyId, form) => CoreAPI.post(`web/v1/accounting/sales-taxes?c_id=${companyId}`, form);
export const updateSalesTax = (companyId, id, form) => CoreAPI.put(`web/v1/accounting/sales-taxes/${id}?c_id=${companyId}`, form);
export const deleteSalesTax = (companyId, id) => CoreAPI.delete(`accounting/v1/sales-tax/${id}/?c_id=${companyId}`);

export const getProducts = (companyId, page, limit, isHelper, query) => CoreAPI.get(`web/v1/accounting/products?c_id=${companyId}&page=${page}&page_size=${limit}&is_helper=${isHelper}${query}`);
export const createProduct = (companyId, form) => CoreAPI.post(`web/v1/accounting/products?c_id=${companyId}`, form);
export const updateProduct = (companyId, id, form) => CoreAPI.put(`web/v1/accounting/products/${id}?c_id=${companyId}`, form);
export const deleteProduct = (companyId, id) => CoreAPI.delete(`accounting/v1/product/${id}/?c_id=${companyId}`);



// ======================== Pay =============================
export const getDeliveryMethods = (companyId, page, limit) => IdentityAPI.get(`web/v1/delivery-methods?c_id=${companyId}&page=${page}&limit=${limit}`);
export const getServicesForProduct = (companyId, name) => IdentityAPI.get(`web/v1/services/for-product/${name}?c_id=${companyId}`);
export const getServicesForProductForGuest = (companyId, name) => IdentityAPI.get(`web/v1/services/for-product/${name}/guest?c_id=${companyId}`);
export const getServiceFeePrice = (companyId, id, amount) => CoreAPI.get(`web/v1/billing/service-fees-price/${id}?c_id=${companyId}&amount=${amount}`);
export const getDeliveryMethodsPrice = (companyId, name, amount) => CoreAPI.get(`web/v1/billing/service-fees-price/${name}?c_id=${companyId}&amount=${amount}`);

export const getPaymentFrequencies = (companyId) => CoreAPI.get(`web/v1/pay/payment-frequencies?c_id=${companyId}`);

export const getBanks = (companyId, page, limit) => CoreAPI.get(`web/v1/account/banks?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getBankAccountTypes = (companyId, page, limit) => CoreAPI.get(`web/v1/account/bank-account-types?c_id=${companyId}&page=${page}&page_size=${limit}`);

export const getCompanyBankAccounts = (companyId, page, limit) => CoreAPI.get(`web/v1/account/company-bank-accounts?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getCompanyBankAccountDetails = (companyId, id) => CoreAPI.get(`web/v1/account/company-bank-accounts/${id}?c_id=${companyId}`);
export const createCompanyBankAccount = (companyId, form) => CoreAPI.post(`web/v1/account/company-bank-accounts?c_id=${companyId}`, form);
export const updateCompanyBankAccount = (companyId, id, form) => CoreAPI.put(`web/v1/account/company-bank-accounts/${id}?c_id=${companyId}`, form);
export const toggleDefaultCompanyBankAccount = (companyId, id) => CoreAPI.put(`web/v1/account/company-bank-accounts/toggle-default/${id}?c_id=${companyId}`);
export const getCompanyBankAccountEligibility = (companyId, paymentHandle) => CoreAPI.get(`web/v1/account/company-bank-accounts/eligibility/${paymentHandle}?c_id=${companyId}`);

export const createVerificationCompanyBankAccount = (companyId, form) => CoreAPI.post(`web/v1/account/company-bank-accounts/verification?c_id=${companyId}`, form);
export const verifyCompanyBankAccount = (companyId, form) => CoreAPI.post(`web/v1/account/company-bank-accounts/verify?c_id=${companyId}`, form);

export const createPlaidLinkTokenCompanyBankAccount = (companyId) => CoreAPI.post(`web/v1/account/company-bank-accounts/plaid/create-link-token?c_id=${companyId}`);
export const exchangePlaidPublicTokenCompanyBankAccount = (companyId, form) => CoreAPI.post(`web/v1/account/company-bank-accounts/plaid/exchange-public-token?c_id=${companyId}`, form);
export const getPlaidAuthCompanyBankAccount = (companyId, form) => CoreAPI.post(`web/v1/account/company-bank-accounts/plaid/auth-info?c_id=${companyId}`, form);

export const getVendors = (companyId, page, limit, query) => CoreAPI.get(`web/v1/pay/vendors?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getVendorDetails = (companyId, id) => CoreAPI.get(`web/v1/pay/vendors/${id}?c_id=${companyId}`);
export const createVendor = (companyId, form) => CoreAPI.post(`web/v1/pay/vendors?c_id=${companyId}`, form);
export const updateVendor = (companyId, id, form) => CoreAPI.put(`web/v1/pay/vendors/${id}?c_id=${companyId}`, form);

export const getVendorsByBusinessHandle = (companyId, query) => CoreAPI.get(`web/v1/pay/vendors-by-business-handle?c_id=${companyId}${query}`);
export const linkPartyAsVendor = (companyId, form) => CoreAPI.post(`web/v1/pay/link-party-as-vendor?c_id=${companyId}`, form);

export const getVendorAccounts = (companyId, id, page, limit) => CoreAPI.get(`web/v1/pay/vendor-bank-accounts/${id}?c_id=${companyId}&page=${page}&page_size=${limit}`, handleRequestCanceller());
export const getVendorAccount = (companyId, vendorId, id) => CoreAPI.get(`web/v1/pay/vendor-bank-accounts/${vendorId}/${id}?c_id=${companyId}`);
export const createVendorAccount = (companyId, id, form) => CoreAPI.post(`web/v1/pay/vendor-bank-accounts/${id}?c_id=${companyId}`, form);
export const updateVendorAccount = (companyId, vendorId, id, form) => CoreAPI.put(`web/v1/pay/vendor-bank-accounts/${vendorId}/${id}?c_id=${companyId}`, form);
export const getVendorBankAccountEligibility = (companyId, vendorId) => CoreAPI.get(`web/v1/pay/vendor-bank-accounts/eligibility/${vendorId}?c_id=${companyId}`);

export const getPayApprovalWorkflows = (companyId, page, limit, query) => CoreAPI.get(`web/v1/pay/workflows?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createPayApprovalWorkflow = (companyId, form) => CoreAPI.post(`web/v1/pay/workflows?c_id=${companyId}`, form);
export const updatePayApprovalWorkflow = (companyId, id, form) => CoreAPI.put(`web/v1/pay/workflows/${id}?c_id=${companyId}`, form);
export const deletePayApprovalWorkflow = (companyId, id) => CoreAPI.delete(`web/v1/pay/workflows/${id}?c_id=${companyId}`);
export const createPayApprovalWorkflowMembers = (companyId, id, form) => CoreAPI.post(`web/v1/pay/workflows-members/${id}?c_id=${companyId}`, form);
export const deletePayApprovalWorkflowMember = (companyId, groupId, userId) => CoreAPI.delete(`web/v1/pay/workflows-members/${groupId}/${userId}?c_id=${companyId}`);

export const getBills = (companyId, page, limit, query) => CoreAPI.get(`web/v1/pay/bills?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const getBill = (companyId, id) => CoreAPI.get(`invoice/v1/bill/${id}/?c_id=${companyId}`);
export const createBill = (companyId, form) => CoreAPI.post(`web/v1/pay/bills?c_id=${companyId}`, form);
export const updateBill = (companyId, id, form) => CoreAPI.put(`web/v1/pay/bills/${id}?c_id=${companyId}`, form);
export const deleteBill = (companyId, id) => CoreAPI.delete(`invoice/v1/bill/${id}/?c_id=${companyId}`);

export const getBillLineDetails = (companyId, id) => CoreAPI.get(`web/v1/pay/bills-line-details/${id}?c_id=${companyId}`)

export const getPayments = (companyId, page, limit, query) => CoreAPI.get(`web/v1/pay/payments?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const getPayment = (companyId, id) => CoreAPI.get(`web/v1/pay/payments/${id}?c_id=${companyId}`);
export const createPayment = (companyId, form) => CoreAPI.post(`web/v1/pay/payments?c_id=${companyId}`, form);
export const createInternationalPayment = (companyId, form) => CoreAPI.post(`web/v1/pay/payments-international?c_id=${companyId}`, form)
export const updatePayment = (companyId, id, form) => CoreAPI.put(`invoice/v1/payment/${id}/?c_id=${companyId}`, form);
export const deletePayment = (companyId, id) => CoreAPI.delete(`invoice/v1/payment/${id}/?c_id=${companyId}`);

export const getPaymentActivities = (companyId, id) => CoreAPI.get(`web/v1/pay/payment-activities/${id}?c_id=${companyId}`);

export const getPaymentsForApprover = (companyId, page, limit, query) => CoreAPI.get(`web/v1/pay/pay-approvals?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const submitPaymentForApproval = (companyId, form) => CoreAPI.post(`web/v1/pay/pay-approvals-for-employees?c_id=${companyId}`, form);
export const actOnPaymentForApproval = (companyId, form) => CoreAPI.post(`web/v1/pay/pay-approvals-for-approvers?c_id=${companyId}`, form);


// ======================== Receive ========================
export const getPaymentHandle = (companyId) => CoreAPI.get(`web/v1/account/payment-handle?c_id=${companyId}`);
export const getPaymentQrCode = (companyId) => CoreAPI.get(`web/v1/account/company-qr-code?c_id=${companyId}`);
export const createPaymentQrCode = (companyId) => CoreAPI.post(`web/v1/account/company-qr-code?c_id=${companyId}`);

export const getCustomers = (companyId, page, limit, query) => CoreAPI.get(`web/v1/receive/customers?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createCustomer = (companyId, form) => CoreAPI.post(`web/v1/receive/customers?c_id=${companyId}`, form);
export const updateCustomer = (companyId, id, form) => CoreAPI.put(`receive/v1/customer/${id}/?c_id=${companyId}`, form);
export const deleteCustomer = (companyId, id) => CoreAPI.delete(`receive/v1/customer/${id}/?c_id=${companyId}`);

export const getCustomersByBusinessHandle = (companyId, query) => CoreAPI.get(`web/v1/receive/customers-by-business-handle?c_id=${companyId}${query}`);
export const linkPartyAsCustomer = (companyId, form) => CoreAPI.post(`web/v1/receive/link-party-as-customer?c_id=${companyId}`, form);

export const getInvoices = (companyId, page, limit, query) => CoreAPI.get(`web/v1/receive/invoices?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getInvoiceDetails = (companyId, id) => CoreAPI.get(`web/v1/receive/invoices/${id}?c_id=${companyId}`);
// export const getInvoiceDetails = (companyId, id) => CoreAPI.get(`receive/v1/invoice/${id}/?c_id=${companyId}`);
export const createInvoice = (companyId, form) => CoreAPI.post(`web/v1/receive/invoices?c_id=${companyId}`, form);
export const updateInvoice = (companyId, id, form) => CoreAPI.put(`web/v1/receive/invoices/${id}?c_id=${companyId}`, form);
export const deleteInvoice = (companyId, id) => CoreAPI.delete(`web/v1/receive/invoices/${id}?c_id=${companyId}`);
export const getInvoiceNumberExists = (companyId, invoiceNumber) => CoreAPI.get(`web/v1/receive/invoice-number-exists?c_id=${companyId}&invoice_number=${invoiceNumber}`);

export const markAsSentInvoice = (companyId, id) => CoreAPI.put(`web/v1/receive/invoice-mark-as-sent/${id}?c_id=${companyId}`);
export const sendInvoice = (companyId, id) => CoreAPI.put(`web/v1/receive/invoice-send/${id}?c_id=${companyId}`);
export const createInvoieDuplicate = (companyId, id, form) => CoreAPI.post(`web/v1/receive/invoice-duplicate/${id}?c_id=${companyId}`, form);
export const getInvoicePDF = (companyId, id) => CoreAPI.get(`web/v1/receive/invoice-pdf/${id}?c_id=${companyId}`);

export const getReceipts = (companyId, page, limit, query) => CoreAPI.get(`web/v1/receive/receipts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const updateReceipt = (companyId, id, form) => CoreAPI.put(`web/v1/receive/receipts/${id}?c_id=${companyId}`, form);


// ======================== Expense ========================
export const getExpenseCategories = (companyId, page, limit, query) => CoreAPI.get(`web/v1/expense/categories?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpenseCategory = (companyId, form) => CoreAPI.post(`web/v1/expense/categories?c_id=${companyId}`, form);
export const updateExpenseCategory = (companyId, id, form) => CoreAPI.put(`web/v1/expense/categories/${id}?c_id=${companyId}`, form);
export const toggleExpenseCategory = (companyId, form) => CoreAPI.put(`web/v1/expense/categories/toggle?c_id=${companyId}`, form);
export const toggleExpenseCategoryPaymentMethod = (companyId, form) => CoreAPI.put(`web/v1/expense/categories/toggle-payment-method?c_id=${companyId}`, form);
export const toggleAllExpenseCategoryPaymentMethod = (companyId, form) => CoreAPI.put(`web/v1/expense/categories/all-toggle-payment-method?c_id=${companyId}`, form);

export const getCostObjects = (companyId) => CoreAPI.get(`web/v1/account/cost-objects?c_id=${companyId}`);
export const createCostObject = (companyId, form) => CoreAPI.post(`web/v1/account/cost-objects?c_id=${companyId}`, form);
export const updateCostObject = (companyId, id, form) => CoreAPI.put(`web/v1/account/cost-objects/${id}?c_id=${companyId}`, form);
export const deleteCostObject = (companyId, id) => CoreAPI.delete(`web/v1/account/cost-objects/${id}?c_id=${companyId}`);

export const getCostAccounts = (companyId, page, limit, query) => CoreAPI.get(`web/v1/account/cost-accounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createCostAccount = (companyId, form) => CoreAPI.post(`web/v1/account/cost-accounts?c_id=${companyId}`, form);
export const updateCostAccount = (companyId, id, form) => CoreAPI.put(`web/v1/account/cost-accounts/${id}?c_id=${companyId}`, form);
export const deleteCostAccount = (companyId, id) => CoreAPI.delete(`web/v1/account/cost-accounts/${id}?c_id=${companyId}`);

export const getExpenseApprovalWorkflows = (companyId, page, limit, query) => CoreAPI.get(`web/v1/expense/workflows?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpenseApprovalWorkflow = (companyId, form) => CoreAPI.post(`web/v1/expense/workflows?c_id=${companyId}`, form);
export const updateExpenseApprovalWorkflow = (companyId, id, form) => CoreAPI.put(`web/v1/expense/workflows/${id}?c_id=${companyId}`, form);
export const deleteExpenseApprovalWorkflow = (companyId, id) => CoreAPI.delete(`web/v1/expense/workflows/${id}?c_id=${companyId}`);
export const createExpenseApprovalWorkflowMembers = (companyId, id, form) => CoreAPI.post(`web/v1/expense/workflows-members/${id}?c_id=${companyId}`, form);
export const deleteExpenseApprovalWorkflowMember = (companyId, groupId, userId) => CoreAPI.delete(`web/v1/expense/workflows-members/${groupId}/${userId}?c_id=${companyId}`);


export const getExpenses = (companyId, page, limit, query) => CoreAPI.get(`web/v1/expense/expenses?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpense = (companyId, form) => CoreAPI.post(`web/v1/expense/expenses?c_id=${companyId}`, form);
export const updateExpense = (companyId, id, form) => CoreAPI.put(`web/v1/expense/expenses/${id}?c_id=${companyId}`, form);
export const deleteExpense = (companyId, id) => CoreAPI.delete(`web/v1/expense/expenses/${id}?c_id=${companyId}`);
export const deleteMultiExpenses = (companyId, form) => CoreAPI.put(`web/v1/expense/expenses-multi-delete?c_id=${companyId}`, form);
export const getExpenseMessages = (companyId, id) => CoreAPI.get(`web/v1/expense/expense-activities/${id}?c_id=${companyId}`);

export const getExpensesForApprover = (companyId, page, limit, query) => CoreAPI.get(`web/v1/expense/expense-approvals?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());

export const sendExpenseForApproval = (companyId, form) => CoreAPI.post(`web/v1/expense/expense-approvals?c_id=${companyId}`, form);
export const submitExpenseForApproval = (companyId, form) => CoreAPI.post(`web/v1/expense/expense-approvals-for-employees?c_id=${companyId}`, form);
export const actOnExpenseForApproval = (companyId, form) => CoreAPI.post(`web/v1/expense/expense-approvals-for-approvers?c_id=${companyId}`, form);

export const getReimbursements = (companyId, page, limit, query) => CoreAPI.get(`web/v1/expense/reimbursements?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const payReimbursements = (companyId, form) => CoreAPI.post(`web/v1/expense/pay-reimbursement?c_id=${companyId}`, form);


// ======================== Wallet ========================
export const getNetworks = (companyId) => CoreAPI.get(`wallet/v1/network/?c_id=${companyId}`);
export const getWalletFrequencies = (companyId) => CoreAPI.get(`wallet/v1/wallet-frequency/?c_id=${companyId}`);

export const getRealCards = (companyId, page, limit) => CoreAPI.get(`wallet/v1/real-card/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createRealCard = (companyId, form) => CoreAPI.post(`wallet/v1/real-card/?c_id=${companyId}`, form);
export const updateRealCard = (companyId, id, form) => CoreAPI.put(`wallet/v1/real-card/${id}/?c_id=${companyId}`, form);
export const deleteRealCard = (companyId, id) => CoreAPI.delete(`wallet/v1/real-card/${id}/?c_id=${companyId}`);

export const getSpendWallets = (companyId, page, limit) => CoreAPI.get(`wallet/v1/spend-wallet/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createSpendWallet = (companyId, form) => CoreAPI.post(`wallet/v1/spend-wallet/?c_id=${companyId}`, form);
export const updateSpendWallet = (companyId, id, form) => CoreAPI.put(`wallet/v1/spend-wallet/${id}/?c_id=${companyId}`, form);
export const deleteSpendWallet = (companyId, id) => CoreAPI.delete(`wallet/v1/spend-wallet/${id}/?c_id=${companyId}`);

export const getSpendWalletEmployees = (companyId, id) => CoreAPI.get(`wallet/v1/spend-wallet-employees/?c_id=${companyId}&ref_spend_wallet_id=${id}`);

export const getEmployeeWallets = (companyId, page, limit) => CoreAPI.get(`wallet/v1/employee-wallet/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createEmployeeWallet = (companyId, form) => CoreAPI.post(`wallet/v1/employee-wallet/?c_id=${companyId}`, form);
export const updateEmployeeWallet = (companyId, id, form) => CoreAPI.put(`wallet/v1/employee-wallet/${id}/?c_id=${companyId}`, form);
export const deleteEmployeeWallet = (companyId, id) => CoreAPI.delete(`wallet/v1/employee-wallet/${id}/?c_id=${companyId}`);

export const getVCN = (companyId, page, limit) => CoreAPI.get(`vc/v1/vcn/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getVCNDetails = (companyId, id) => CoreAPI.get(`vc/v1/vcn/${id}/?c_id=${companyId}`);
export const createVCN = (companyId, form) => CoreAPI.post(`vc/v1/vcn/?c_id=${companyId}`, form);
export const deleteVCN = (companyId, id) => CoreAPI.delete(`vc/v1/vcn/${id}/?c_id=${companyId}`);
export const getVCNCardsOnFile = (companyId, page, limit) => CoreAPI.get(`vc/v1/card-on-file/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const sendCardsOnFile = (companyId, form) => CoreAPI.post(`vc/v1/card-on-file-send/?c_id=${companyId}`, form);


// ======================== International =======================
export const getWalletBalance = (companyId) => CoreAPI.get(`web/v1/int-cc/balance?c_id=${companyId}`);
export const addWalletBalance = (companyId, form) => CoreAPI.post(`web/v1/int-cc/add-balance?c_id=${companyId}`, form);

export const getWithdrawalAccounts = (companyId) => CoreAPI.get(`web/v1/int-cc/withdrawal-accounts?c_id=${companyId}`);
export const pullFundsFromWithdrawalAccounts = (companyId, form) => CoreAPI.post(`web/v1/int-cc/withdrawal-accounts/pull-funds?c_id=${companyId}`, form);

export const getWalletActivities = (companyId, page, limit) => CoreAPI.get(`web/v1/int-cc/activities?c_id=${companyId}&page=${page}&page_size=${limit}`);

export const getDetailedRates = (companyId, query, cancellable) => CoreAPI.get(`web/v1/int-cc/rates/detailed?c_id=${companyId}${query}`,  handleRequestCanceller(cancellable));

export const getPurposeCodes = (companyId, query) => CoreAPI.get(`web/v1/int-cc/purpose-codes?c_id=${companyId}${query}`);

export const getBeneficiaryRequirements = (companyId, query) => CoreAPI.get(`web/v1/int-cc/beneficiary-required-details?c_id=${companyId}${query}`);

export const getPaymentPurposes = (companyId, query) => CoreAPI.get(`web/v1/int-cc/payment-purpose?c_id=${companyId}${query}`);

export const getPaymentServices = (companyId, query) => CoreAPI.get(`web/v1/int-cc/payment-services?c_id=${companyId}${query}`);


// ======================== Billing =============================
export const getBillingPay = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/billing-pay?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingReceive = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/billing-receive?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingExpense = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/billing-expense?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingCrossBorderPayment = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/billing-cross-border-payment?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getPlanBilling = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/plan-billing?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getAddonBilling = (companyId, page, limit, query) => CoreAPI.get(`web/v1/billing/addon-billing?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);


// ======================== Payment page ========================
export const getCompanyFromPaymentHandle = (paymentHandle) => CoreAPI.get(`web/v1/account/company-payment-handle/${paymentHandle}`);
export const getInvoiceFromToken = (companyId, token) => CoreAPI.get(`web/v1/receive/invoice-from-token/${token}?c_id=${companyId}`);

// test
export const payInvoice = (companyId, form) => CoreAPI.post(`web/v1/receive/pay-invoice?c_id=${companyId}`, form);


// ======================== Miscellaneous ========================
export const getEmployeesSearch = (companyId, page, limit) => CoreAPI.get(`web/v1/account/employees-list?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const uploadFiles = (form) => IdentityAPI.post(`web/v1/upload-files`, form);
export const getCurrencies = () => CoreAPI.get(`web/v1/currency/?page=1&page_size=500`);

export const getHolidays = (companyId) => CoreAPI.get(`web/v1/account/holidays?c_id=${companyId}`);