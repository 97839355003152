import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  Skeleton, 
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import { 
  CreateCustomer, 
  GetCustomersByBusinessHandle, 
  LinkPartyAsCustomer, 
  UpdateCustomer 
} from '../../../../redux/actions/receive/customersActions';

import { 
  CLEAR_CUSTOMERS_BY_BUSINESS_HANDLE_RECEIVE 
} from '../../../../redux/actionTypes';

import "./styles.scss";

const Data = {
  Name: "",
  ContactName: "",
  Email: "",
  Phone: "",
  DefaultPaymentTerm: 30,
  party_handle: ""
};

const NEWCustomersModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [partyLoading, setPartyLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [partyLinking, setPartyLinking] = useState(false);
  const [mode, setMode] = useState(-1);

  const [partiesList, setPartiesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      dispatch({ type: CLEAR_CUSTOMERS_BY_BUSINESS_HANDLE_RECEIVE });
      setPartiesList([]);

      if(update?.defaultMode === 1){
        // mode = 1 - create customer
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit customer
        setMode(2);
        setFormData({
          ...formData,
          Name: update?.name || "",
          ContactName: update?.contact_name || "",
          Email: update?.email || "",
          Phone: update?.phone || "",
          DefaultPaymentTerm: update?.default_payment_term || 30
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatPartiesList(state.customersByBusinessHandleList.records || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatPartiesList = (list) => {
    setPartiesList(list);
  };

  async function handleGetCustomersByHandle(handle) {
    let query = `&search=${handle}`;
    dispatch(GetCustomersByBusinessHandle(companyId, query, setPartyLoading));
  };

  const handleChange = (e) => {
    if(e.target.name === "Phone"){
      setFormData({ ...formData, [e.target.name]: e.target.value.substring(0, 20)  });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create customer
        let obj = {
          "name": formData.Name || "",
          "contact_name": formData.ContactName || "",
          "email": formData.Email || "",
          "phone": formData.Phone || "",
          "default_payment_term": parseInt(formData.DefaultPaymentTerm) || 30
        };
        dispatch(CreateCustomer(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit customer
        let obj = {
          "name": formData.Name || "",
          "contact_name": formData.ContactName || "",
          "email": formData.Email || "",
          "phone": formData.Phone || "",
          "default_payment_term": parseInt(formData.DefaultPaymentTerm) || 30
        };
        dispatch(UpdateCustomer(companyId, update?.customer_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  function handleLinkPartyAsCustomer(formData){
    dispatch(LinkPartyAsCustomer(companyId, formData, setPartyLinking)).then(() => {
      handleGet(setPartyLinking).then(() => {
        handleClose();
      })
    })
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={handleClose}
            title={title} 
            />

          <Container 
            maxWidth="sm">

            {/* <Typography
              variant='h4'
              textAlign={"center"}>
              Add customer
            </Typography> */}

            <Box 
              className="flex__Column"
              gap={"20px"}
              mt={"20px"}>  

              <Typography
                variant='subtitle2'
                textAlign={"center"}
                color={"grey"}>
                Search by party ID
              </Typography>
                
              <TextField 
                label="Party ID"
                fullWidth
                name='party_handle'
                placeholder='Start entering party ID'
                value={formData.party_handle}
                onChange={(e) => {
                  handleGetCustomersByHandle(e.target.value);
                  handleChange(e);
                }}
                inputProps={{
                  maxLength: 100
                }}
                />

                {
                  formData?.party_handle?.length > 2
                  ?
                  <Typography
                    variant='subtitle2'
                    textAlign={"center"}
                    color={"grey"}>
                    Matching customers
                  </Typography>
                  :
                  <Typography
                    variant='subtitle2'
                    textAlign={"center"}
                    color={"grey"}>
                    OR
                  </Typography>
                }
              
              {
                formData?.party_handle?.length > 2
                &&
                <Box
                  className="flex__Column"
                  width={"100%"}
                  gap={"15px"}
                  sx={{ 
                    padding: "10px",
                    overflow: "auto",
                    height: "calc(100vh - 270px)"
                  }}>
                  {
                    partyLoading
                    ?
                    <Box
                      width={"100%"}
                      className="flex__Column"
                      gap={"10px"}>
                      <Skeleton
                        variant='rectangular'
                        sx={{ height: "120px", borderRadius: "16px" }}
                        />
                      <Skeleton
                        variant='rectangular'
                        sx={{ height: "120px", borderRadius: "16px" }}
                        />
                    </Box>
                    :
                    partiesList.map((party, partyIndex) => (
                      <Box
                        key={partyIndex}
                        className="flex__Column party"
                        gap={"10px"}>
                        <Box
                          className="flexCenterSBRow">
                          <Box>
                            <Typography
                              className='flexCenterSBRow'
                              variant='subtitle2'
                              fontWeight={600}>
                              {party.party_handle || <NA />}
                            </Typography>
                            <Typography
                              className='flexCenterSBRow'>
                              {party.legal_name || <NA />}
                            </Typography>
                            <Typography
                              className='flexCenterSBRow'
                              variant='caption'>
                              {party.address || <NA />},&nbsp;{party.city || <NA />},&nbsp;{party.state || <NA />}
                            </Typography>
                            <Typography
                              className='flexCenterSBRow'
                              variant='caption'>
                              {party.country_code || <NA />},&nbsp;{party.pincode || <NA />}
                            </Typography>
                          </Box>
                          {/* <Box>
                            <Typography
                              variant='subtitle1'
                              color={"grey"}
                              mr={"20px"}>
                              {party.verification_level || <NA />}
                            </Typography>
                          </Box> */}
                        </Box>
                        <Box>
                          <Typography
                            className='flexCenterSBRow'>
                            Bank accounts: {party.party_bank_accounts.length}
                          </Typography>
                        </Box>
                        <Box 
                          className="flexCenterFERow">
                          <Box>
                            <LoadingButton
                              variant="contained"
                              disabled={party.party_bank_accounts.length < 1}
                              onClick={() => handleLinkPartyAsCustomer(party)}>
                              Add
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                </Box>
              }

              {
                formData?.party_handle?.length <= 2
                &&
                <Box 
                  className="flex__Column"
                  gap={"20px"}
                  mt={"0px"}>  
                  <Box 
                    className="flexFSSBRow"
                    gap="15px">
                    <TextField 
                      label="Name"
                      fullWidth
                      name="Name"
                      value={formData.Name}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 80
                      }}
                      />
                  </Box>
                  <Box 
                    className="flexFSSBRow"
                    gap="15px">
                    <TextField 
                      label="Email"
                      fullWidth
                      name="Email"
                      value={formData.Email}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 80
                      }}
                      />
                    <TextField 
                      label="Phone"
                      fullWidth
                      name="Phone"
                      type='number'
                      value={formData.Phone}
                      onChange={handleChange}
                      />
                  </Box>
                  <Box 
                    className="flexFSSBRow"
                    gap="15px">
                    <TextField 
                      label="Contact name"
                      fullWidth
                      name="ContactName"
                      value={formData.ContactName}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 80
                      }}
                      />
                    <TextField 
                      label="Default Payment Term"
                      fullWidth
                      name="DefaultPaymentTerm"
                      type='number'
                      value={formData.DefaultPaymentTerm}
                      onChange={handleChange}
                      />
                  </Box>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    disabled={!(formData.Email !== "" && formData.Phone !== "" &&
                      formData.ContactName !== "" && formData.Name !== "")}
                    onClick={handleSubmit}>
                    {mode === 1 ? "Submit" : "Update"}
                  </LoadingButton>
                </Box>
              }
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default NEWCustomersModal;