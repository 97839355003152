import React from 'react';

import { 
  Box, 
  IconButton, 
  Typography 
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../../global.scss";

const ModalTopBar = ({
  onBack,
  title
}) => {
  return (
    <Box
      className="flexCenter_Row"
      position={"sticky"}
      top={"0px"}
      zIndex={"100"}
      padding={"20px"} 
      backgroundColor="white"
      width={"100%"}
      gap={"20px"}>
      {
        onBack
        &&
        <IconButton
          onClick={onBack}>
          <ArrowBackIcon 
            sx={{ color: "grey", fontSize: "36px" }}/>
        </IconButton>
      }
      <Box
        width={"100%"}
        className="flexCenterCenterRow">
        <Typography
          variant='h5'>
          {title}
        </Typography>
      </Box>
      <Box
        width={"52px"}>
      </Box>
    </Box>
  )
}

export default ModalTopBar;