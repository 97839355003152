import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OTPInput from 'react-otp-input';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone';

import { 
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  Container, 
  Divider, 
  FormControl, 
  FormControlLabel, 
  FormLabel, 
  IconButton, 
  MenuItem, 
  Radio, 
  RadioGroup, 
  Skeleton, 
  TextField, 
  Tooltip, 
  Typography, 
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import { stateList } from '../../../components/utils/usStateCodeList';

import { 
  PayInvoice
} from '../../../redux/actions/makePayment/invoicesActions';
import { 
  GetServiceFeePrice,
  GetServicesForProductForGuest,
  // GetDeliveryMethods, 
  // GetDeliveryMethodsPrice, 
} from '../../../redux/actions/pay/paymentsActions';
import { 
  AuthenicateGuest, 
  SendGuestOtp, 
  VerifyGuestOtp
} from '../../../redux/actions/guest/authenicateActions';
import { 
  CreateCompanyForGuest,
  GetGuestBasicInfo 
} from '../../../redux/actions/guest/infoActions';
import { 
  CreateVerificationCompanyBankAccount,
  GetBankAccountTypes, 
  GetBanks, 
  GetCompanyBankAccounts
} from '../../../redux/actions/pay/companyBankAccountsActions';

import BankAccountModal from './BankAccountModal';

import "../styles.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const taxIdTypeList = [
  { label: "EIN", value: "ein" },
  { label: "TIN", value: "tin" }
];

const GuestFlow = ({
  onBack,
  company, 
  invoice, 
  formData,
  setFormData,
  handleChange
}) => {
  const dispatch = useDispatch();
  const pay = useSelector(state => state.pay);
  const other = useSelector(state => state.other);
  const { 
    handleCalculateAmount, 
    handleShowAmount 
  } = useCurrencyService();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingServiceFeePrice, setLoadingServiceFeePrice] = useState(false);
  const [bankAccountModalOpen, setBankAccountModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [guestCompanyId, setGuestCompanyId] = useState("");

  // after step 2
  const [bankAccountsList, setBankAccountsList] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    
  const [servicesList, setServicesList] = useState([]);
  const [serviceFees, setServiceFees] = useState([]);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  
  const [feeAmount, setFeeAmount] = useState(0);

  const [holidaysList, setHolidaysList] = useState([]);

  const [step, setStep] = useState(-1);
  // step = 1 - loading screen for send code
  // step = 2 - enter verify code
  // step = 3 - basic details
  // step = 4 - select bank account
  // step = 5 - select date & delivery method
  // step = 6 - review & confirm
  // step = 7 - payment paid or scheduled

  const [otp, setOtp] = useState("");
  const [refId, setRefId] = useState("");

  const [verifyId, setVerifyId] = useState(null);
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    setStep(1);
    setOtp("");
    setRefId("");

    if("GuestEmail" in formData && formData.GuestEmail.length > 0){
      handleGuestUserLogin();
    };

    setSelectedServiceId(null);
    setSelectedService(null);
    setSelectedBankAccount(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(pay.companyBankAccountsList.records || []);
      formatServicesList(pay.servicesForProductForGuestList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pay, step]);

  useEffect(() => {
    try {
      formatHolidaysList(other.holidaysList || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  // -------------- formatters --------------
  const formatServicesList = (list) => {
    setServicesList(list);
    if (step === 5) handleGetServiceFeePrice(list);
  };

  const formatCompanyBankAccountsList = (list) => {
    setBankAccountsList(list)
  };

  const formatHolidaysList = (list) => {
    setHolidaysList(list);

    // check if today is valid of not
    let today = dayjs()
    let addDays = 0;
    while(!findValidDebitDate(today, addDays, list)){
      addDays++;
    };
    setFormData({
      ...formData,
      Date: dayjs(today).add(addDays, 'day')
    });
  };

  // -------------- api calls --------------
  async function handleGetMyBankAccounts(id) {
    dispatch(GetCompanyBankAccounts(id, 1, 500, setLoading));
  }

  const handleGetServicesForProduct = () => {
    dispatch(GetServicesForProductForGuest(guestCompanyId, "Receive", setLoading2)).then(() => {
      handleNextStep();
    });   
  };
  
  // -------------- utility functions ----------
  function findValidDebitDate(today, addDays, holidays){
    let date = dayjs(today).add(addDays, 'day');
    let isValid = true;

    holidays.forEach((i) => {
      if (i === date.format("YYYY-MM-DD")) {
        isValid = false;
      }
    });

    // to disable all weekends
    if(isValid){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        isValid = true;
      } else {
        isValid = false;
      };
    };

    return isValid;
  };

  const disableSpecificDates = (date) => {
    let disabled = false;

    holidaysList.forEach((i) => {
      if (i.date === date.format("YYYY-MM-DD")) {
        disabled = true;
      }
    });

    // to disable all weekends
    if(!disabled){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        disabled = false;
      } else {
        disabled = true;
      };
    };

    return disabled;
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleGetServiceFeePrice = async (list) => {
    let rate = [];

    for (const service of list) {
      try { 
        let priceInInt = handleCalculateAmount(formData.Currency, formData.Price)

        await dispatch(GetServiceFeePrice(guestCompanyId, service.id, priceInInt, setLoadingServiceFeePrice))
          .then(({res, statusCode}) => {
          if(statusCode === 200){
            rate.push(res.data)
          }
        });
      } catch (error) {
        console.error(`Error fetching data for object with id ${service.id}:`, error);
      }
    }

    setServiceFees(rate);
  };

  const handleDeliveryMethod = (method) => {
    switch(method){
      case "INSTANT_TRANSFER": return "Instant Transfer";
      case "SAME_DAY_ACH": return "Same day ACH";
      case "ONE_DAY_ACH": return "One day ACH";
      case "NEXT_DAY_ACH": return "Next-day delivery"
      case "STANDARD_ACH": return "3 business days";
      case "DOMESTIC_WIRE": return "Domestic wire";

      default: return null;
    }
  };

  // -------------- handle submit --------------
  const handlePay = () => {
    try {
      let obj = {
        "ref_company_id": company.company_id,
        "currency": formData.Currency,
        "amount": handleCalculateAmount(formData.Currency, formData.Price || 0),
        "payer_bank_id": selectedBankAccount.id,
        "service_id": selectedServiceId,
        "debit_date": dayjs(formData.Date).format('YYYY-MM-DD'),
        "invoice_number": formData.Invoice,
        "guest_payer": true,
      };

      if(invoice){
        obj = {
          ...obj,
          "invoice_id": invoice.invoice_id,
        }
      }

      console.log(obj);

      // this company id should the company id of the guest user of logged in user.
      dispatch(PayInvoice(guestCompanyId, obj, setLoading)).then(({ res, statusCode }) => {
        setLoading(false);
        if(statusCode === 201){
          handleNextStep();
        }
      });

    } catch (err) {
      console.log(err)
    }
  };

  const handleGuestUserLogin = () => {
    let obj = {
      email: formData.GuestEmail || ""
    };

    dispatch(AuthenicateGuest(obj, setLoading)).then(({res, statusCode}) => {
      if (statusCode === 201) {
        // trigger otp
        dispatch(SendGuestOtp({ to: formData.GuestEmail }, setLoading)).then(({res, statusCode}) => {
          if (statusCode === 201) {
            setRefId(res.data.refId);
            // handleNextStep();
            setStep(2);
          }
        })
      }
    });
  };  

  const handleGuestUserVerify = () => {
    let obj = {
      "ref_id": refId,
      "to": formData.GuestEmail,
      "otp": parseInt(otp+""),
      "region": "US"
    }

    dispatch(VerifyGuestOtp(obj, setLoading)).then(async ({res, statusCode}) => {
      if (statusCode === 202) {
        await handleGetBasicInfo();
      }
    });
  };

  const handleCreateCompanyForGuest = () => {
    let obj = {
      "legal_name": formData.GuestName || "",
      "address": formData.GuestAddress || "",
      "city": formData.GuestCity || "",
      "state": formData.GuestState || "",
      "country": formData.GuestCountry || "",
      "pincode": formData.GuestPincode || "",
      "tax_id": formData.GuestTaxId || "",
      "tax_id_type": formData.GuestTaxIdType || "",
      // "incorporation_date": dayjs().add(-1, 'day').format("YYYY-MM-DD") || ""
    };
    dispatch(CreateCompanyForGuest(obj, setLoading)).then(() => {
      handleGetBasicInfo()
    });
  };

  const handleGetBasicInfo = async () => {
    dispatch(GetGuestBasicInfo(setLoading)).then(({res, statusCode}) => {
      if(statusCode === 200){
        if(res.data.company_exists === true){
          setGuestCompanyId(res.data.company_id);
          handleGetMyBankAccounts(res.data.company_id);
          dispatch(GetBankAccountTypes(res.data.company_id, 1, 50, setLoading2));
          dispatch(GetBanks(res.data.company_id, 1, 500, setLoading2));
          setStep(4);
        } else {
          setStep(3);
        }
      }
    });
  };

  // -------------------- bank account verify --------------------
  const handleVerifyBankAccount = (id) => {
    setVerifyId(id);
    let obj = {
      "bank_account_id": id
    };
    dispatch(CreateVerificationCompanyBankAccount(update?.companyId, obj, setVerifying)).then(() => {
      dispatch(GetCompanyBankAccounts(update?.companyId, 1, 500, setVerifying)).then(() => {
        setVerifyId(null);
      });
    });
  };

  function getBankStatusVerifyComment(status){
    switch(status){
      case "valid": return "- Bank account is open and valid";
      case "closed": return "- Bank account is closed";
      case "invalid": return "- Account information is invalid";
      case "failed": return "- The verification request has failed";
      case "pending": return "";
      case null: return "";

      default: return "Invalid status";
    }
  };

  function getBankControlVerifyComment(status){
    switch(status){
      case "valid": return "";
      case "pending": return "";
      case "canceled": return "- The maximum number of attempts has been reached or it has been 5 days since the request was sent to the receiving bank";
      case null: return "";

      default: return "Invalid status";
    }
  };

  return (
    <Box>

      <BankAccountModal
        open={bankAccountModalOpen}
        setOpen={setBankAccountModalOpen} 
        title={modalTitle}
        update={update}
        handleGet={handleGetMyBankAccounts}
        />
      {
        step === 1
        // loading screen for send otp
        ?   
        <Container
          maxWidth="sm">
          {
            loading
            &&
            <Box
              className="makepayment__bottom 
                flexCenterCenterColumn">
              <CircularProgress 
                size={"65px"} 
                />
            </Box>
          }
        </Container>
        :
          step === 2
          // enter code
          ?
          <Container
            maxWidth="sm">
            <Box
              className="flexCenter_Row"
              mt={"20px"}>
              <IconButton
                onClick={onBack}>
                <ArrowBackOutlinedIcon />
              </IconButton>
            </Box>
            <Box
              className="flexCenterCenterColumn"
              width={"100%"}
              height={"calc(100vh - 250px)"}>
              <Box
                className="flex__Column"
                minWidth={"400px"}
                gap="20px">
                <Typography
                  textAlign={"center"}
                  variant='h4'>
                  Verify it's you
                </Typography>
                <Typography
                  mt={"20px"}
                  variant='subtitle2'
                  textAlign={"center"}>
                  For your security, we have sent 6-digit code to <b>{formData.GuestEmail}</b>, 
                  Enter code below
                </Typography>
                <OTPInput
                  value={otp}
                  onChange={(e) => {setOtp(e)}}
                  shouldAutoFocus={true}
                  numInputs={6}
                  isInputSecure={true}
                  containerStyle={"auth__content-otp"}
                  inputStyle={"auth__content-otp-input"}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />
                  }
                />
                <LoadingButton
                  variant={"contained"}
                  onClick={handleGuestUserVerify}
                  loading={loading}>
                  Continue
                </LoadingButton> 
              </Box>
            </Box>
          </Container>
          :
            step === 3
            // basic details
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Row"
                mt={"20px"}>
                <IconButton
                  onClick={onBack}>
                  <ArrowBackOutlinedIcon />
                </IconButton>
              </Box>
              <Box
                className="flexCenterCenterColumn"
                width={"100%"}
                height={"calc(100vh - 250px)"}>
                <Typography
                  textAlign={"center"}
                  variant='h4'>
                  Few basic details
                </Typography>
                <Box
                  className="flex__Column"
                  gap={"15px"}
                  pt="20px">
                  <TextField 
                    label="Your full name *"
                    fullWidth
                    name="GuestName"
                    value={formData.GuestName}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 128
                    }}
                    />
                  <TextField 
                    label="Address *"
                    fullWidth
                    name="GuestAddress"
                    value={formData.GuestAddress}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 256
                    }}
                    />
                  <Box
                    className="flexCenter_Row"
                    gap="15px">
                    <TextField 
                      label="City *"
                      fullWidth
                      name="GuestCity"
                      value={formData.GuestCity}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 128
                      }}
                      />
                    <TextField 
                      select
                      label="State *"
                      fullWidth
                      name="GuestState"
                      value={formData.GuestState}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 128
                      }}
                      >
                      {
                        stateList.map((state, stateIndex) => (
                          <MenuItem
                            key={stateIndex}
                            value={state.code}>
                            <Typography>
                              {state.name}
                            </Typography>
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>
                  <Box
                    className="flexCenter_Row"
                    gap="15px">
                    <TextField 
                      label="Country *"
                      fullWidth
                      name="GuestCountry"
                      disabled
                      value={formData.GuestCountry}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 128
                      }}
                      />
                    <TextField 
                      label="Postal code *"
                      fullWidth
                      type='number'
                      name="GuestPincode"
                      value={formData.GuestPincode}
                      onChange={handleChange}
                      />
                  </Box>
                  <Typography
                    mt={"20px"}
                    mb={"10px"}
                    variant='substile1'>
                    Tax Information
                  </Typography>
                  <Box
                    className="flexCenter_Row"
                    gap="15px">
                    <TextField 
                      select
                      label="Tax Id Type *"
                      fullWidth
                      name="GuestTaxIdType"
                      value={formData.GuestTaxIdType}
                      onChange={handleChange}>
                      {
                        taxIdTypeList.map((taxIdType, taxIdTypeIndex) => (
                          <MenuItem
                            key={taxIdTypeIndex}
                            value={taxIdType.value}>
                            <Typography>
                              {taxIdType.label}
                            </Typography>
                          </MenuItem>
                        ))
                      }
                    </TextField>
                    <TextField 
                      label="Tax Id *"
                      fullWidth
                      type='number'
                      name="GuestTaxId"
                      value={formData.GuestTaxId}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 9
                      }}
                      />
                  </Box>
                  <LoadingButton
                    variant="contained"
                    disabled={!(formData.GuestName !== "" && formData.GuestAddress !== "" &&
                      formData.GuestCity !== "" && formData.GuestState !== "" &&
                      formData.GuestCountry !== "" && formData.GuestPincode !== ""
                    )}
                    onClick={handleCreateCompanyForGuest}
                    loading={loading}>
                    Continue
                  </LoadingButton>
                </Box>
              </Box>
            </Container>
            :
              step === 4
              // select bank
              ?
              <Container
                maxWidth="sm">
                <Box
                  className="flexCenter_Row"
                  mt={"20px"}>
                  <IconButton
                    onClick={onBack}>
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                </Box>
                <Box
                  className="flexCenter_Column"
                  gap={"40px"}
                  mt={"20px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}>
                    How do you want to pay?
                  </Typography> 
                  <Typography
                    textAlign={"center"}>
                    <b>{handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}</b>
                    &nbsp;to&nbsp;
                    <b>{company.company_name}</b>
                  </Typography> 
                  <Box
                    className="flex__Column"
                    width={"100%"}
                    gap={"20px"}>
                    <Box
                      className="flexCenterSBRow">
                      <Typography
                        textAlign={"left"}
                        variant='subtitle2'>
                        Bank accounts
                      </Typography>
                      <Button
                        variant='contained'
                        onClick={() => {
                          setModalTitle("Add Bank account");
                          setUpdate({
                            defaultMode: 1,
                            companyId: guestCompanyId
                          });
                          setBankAccountModalOpen(!bankAccountModalOpen);
                        }}>
                        Add Bank account
                      </Button>
                    </Box>
                    {
                      loading
                      ?
                      <>
                        <Skeleton
                          variant='rectangle'
                          sx={{ width: "100%", borderRadius: "16px", height: "92.5px",  }}/>
                      </>
                      : 
                        bankAccountsList.length === 0
                        ?
                        <Typography textAlign={"center"}>No bank account found</Typography>
                        :
                          bankAccountsList.map((item, index) => (
                            <Box
                              key={index}
                              className={`flex__Column ${item?.verified === true ? 'makepayment__bank' : 'makepayment__bank-disabled'}  
                                ${selectedBankAccount?.id === item.id && 'makepayment__bank-selected'}`}
                              onClick={item.verified === true 
                                ? () => setSelectedBankAccount(item)
                                : () => {}}>
                               <Box
                                className="flexCenter_Row"
                                gap={"20px"}>
                                <AccountBalanceOutlinedIcon 
                                  fontSize="large"
                                  />
                                <Box
                                  sx={{ marginRight: "auto" }}>
                                  <Typography variant='subtitle2'>
                                    {item?.account_type_id?.type || <NA />}
                                    &nbsp;-&nbsp;
                                    {item.account_number?.slice(-5) || <NA />}</Typography>
                                  {/* <Typography color={"grey"}>Free</Typography> */}
                                  <Box
                                    className="flexCenter_Row"
                                    gap={"10px"}>
                                    {
                                      item.is_default === true
                                      &&
                                      <Chip
                                        color='warning'
                                        size='small'
                                        label={'Primary'} 
                                        />
                                    }
                                    {
                                      item.verification_pending === false && item.verified === false
                                      &&
                                      <Chip
                                        color='error'
                                        size='small'
                                        label={'Unverified'} 
                                        />
                                    }
                                  </Box>
                                </Box>
                                {
                                  item.verification_pending === true
                                  ?
                                  <Tooltip>
                                    <Chip
                                      color='warning'
                                      label={"Verification pending"} 
                                      // clickable={true}
                                      // onClick={() => {
                                        
                                      // }}
                                      />
                                  </Tooltip>
                                  :
                                  <Box
                                    className="flexCenter_Row"
                                    gap={"5px"}>
                                    {
                                      item.verified === false
                                      &&
                                        (item.id === verifyId && verifying
                                        ?
                                        <CircularProgress 
                                          size={28}
                                          />
                                        :
                                        <Chip
                                          color='info'
                                          label={"Verify"} 
                                          clickable={true}
                                          onClick={() => {
                                            handleVerifyBankAccount(item.id);
                                          }}
                                          />)
                                    }
                                    <IconButton
                                      onClick={(e) => {
                                        // setBankAccountsMenuAnchor(e.currentTarget);
                                        // setBankAccountsMenuOpen(true);
                                        // setDeleteId(row.id);
                                        setUpdate(item);
                                        setModalTitle(item.account_number || "");
                                      }}>
                                      <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                                    </IconButton>
                                  </Box>
                                }
                              </Box>
                              {
                                item.verification_pending === true
                                &&
                                <Box
                                  pt={"20px"}
                                  pl={"55px"}
                                  className="flex__Column"
                                  gap="10px">
                                  <Box
                                    className="flexCenter_Row"
                                    gap={"10px"}>
                                    {
                                      item.verification_status === "valid"
                                      ?
                                      <CheckCircleOutlinedIcon 
                                        color='success'
                                        fontSize='small'
                                        />
                                      :
                                        ["closed", "invalid", "failed"].includes(item.verification_status)
                                        ?
                                        <CancelOutlinedIcon 
                                          color='error'
                                          fontSize='small'
                                          />
                                        :
                                          item.verification_status === "pending" || item.verification_status === null
                                          ?
                                          <CircularProgress 
                                            size={17}
                                            />
                                          :
                                          <Box 
                                            width={"20px"}
                                            height={"20px"} />
                                    }
                                    <Typography
                                      fontWeight={500}>
                                      Account status
                                    </Typography>
                                    <Typography
                                      ml={"-5px"}>
                                      {getBankStatusVerifyComment(item.verification_status)}
                                    </Typography>
                                  </Box>
                                  <Box
                                    className="flexFS_Row"
                                    gap={"10px"}>
                                    {
                                      item.verification_status === "valid"
                                      ?
                                        item.control_status === "valid"
                                        ?
                                        <CheckCircleOutlinedIcon 
                                          color='success'
                                          fontSize='small'
                                          />
                                        :
                                          item.control_status === "canceled"
                                          ?
                                          <CancelOutlinedIcon 
                                            color='error'
                                            fontSize='small'
                                            />
                                          :
                                            item.control_status_applied === false
                                            ?
                                            <Box 
                                              width={"20px"}
                                              height={"20px"} />
                                            :
                                              item.control_status === "pending" || item.control_status === null
                                              ?
                                              <CircularProgress 
                                                size={17}
                                                sx={{ margin: "1.5px" }}
                                                />
                                              :
                                              <Box 
                                                width={"20px"}
                                                height={"20px"} />
                                        :
                                        <Box 
                                          width={"20px"}
                                          height={"20px"} />
                                    }
                                    <Typography
                                      fontWeight={500}
                                      color={item.verification_status === "valid" ? "black" : "grey"}>
                                      Account access
                                    </Typography>
                                    <Typography
                                      ml={"-5px"}>
                                      {getBankControlVerifyComment(item.control_status)}
                                    </Typography>
                                    {
                                      (item.control_status_applied === false 
                                        && item.verification_status === "valid" 
                                        && item.control_status !== "canceled")
                                      &&
                                      <Tooltip>
                                        <Chip
                                          color='info'
                                          label={"Submit code"} 
                                          clickable={true}
                                          onClick={() => {
                                            setUpdate({
                                              defaultMode: 3, 
                                              bank_account_id: item.id
                                            });
                                            setModalTitle("Enter statement code");
                                            setBankAccountModalOpen(!bankAccountModalOpen);
                                          }}
                                          />
                                      </Tooltip>
                                    }
                                  </Box>
                                </Box>
                              }
                            </Box>
                          ))
                    }
                  </Box>
                  <LoadingButton
                    variant="contained"
                    disabled={selectedBankAccount === null}
                    loading={loading2}
                    onClick={handleGetServicesForProduct}>
                    Continue
                  </LoadingButton>
                </Box>
              </Container>
              :
                step === 5
                // select debit date & delivery method
                ?
                <Container
                    maxWidth="sm">
                    <Box
                      className="flexCenter_Row"
                      mt={"20px"}>
                      <IconButton
                        onClick={handlePrevStep}>
                        <ArrowBackOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box
                      className="flexCenter_Column"
                      gap={"30px"}>
                      <Typography
                        variant='h4'
                        textAlign={"center"}
                        mt={"20px"}>
                        Choose the date you want this payment deducted.
                      </Typography> 

                      <Typography
                        textAlign={"center"}>
                        Choose the date for your payment to go out.
                      </Typography> 

                      <Box
                        className="flexCenter_Column"
                        width={"100%"}
                        gap={"10px"}>
                        <Box  
                          width={"340px"}
                          className="flexCenterCenterRow">
                          {/* <Box
                            className="flexCenter_Row"
                            gap={"5px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Invoice Date
                            </Typography>
                            <Typography
                              variant='caption'
                              fontWeight={500}>
                              {
                                mode === 1
                                ? dayjs(update?.invoice_date)?.format("MMM DD, YYYY")
                                : mode === 2
                                  ? dayjs(update?.created_at)?.format("MMM DD, YYYY")
                                  : ""
                              }
                            </Typography>
                          </Box> */}
                          {
                            invoice
                            &&
                            <Box
                              className="flexCenter_Row"
                              gap={"5px"}>
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Invoice due date
                              </Typography>
                              <Typography
                                variant='caption'
                                fontWeight={500}>
                                {dayjs(formData?.InvoiceDueDate).format("MMM DD, YYYY")}
                              </Typography>
                            </Box>
                          }
                        </Box>
                        <LocalizationProvider 
                          sx={{ maxHeight: "100px" }} 
                          dateAdapter={AdapterDayjs}>
                          <DateCalendar 
                            // minDate={dayjs()}
                            maxDate={formData.InvoiceDueDate ? dayjs(formData.InvoiceDueDate) : null}
                            shouldDisableDate={disableSpecificDates}
                            value={formData.Date}
                            onChange={(e) => {
                              setSelectedServiceId(null);
                              handleChange({
                                target: {
                                  name: "Date",
                                  value: e
                                }
                              })
                            }} 
                            />
                        </LocalizationProvider>
                        <Box  
                          width={"340px"}
                          mt="-30px"
                          mb="20px"
                          className="flexCenterCenterRow">
                          <Box
                            className="flexCenter_Row"
                            gap={"5px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Debit date
                            </Typography>
                            <Typography
                              variant='caption'
                              fontWeight={500}>
                              {dayjs(formData?.Date).format("MMM DD, YYYY")}
                            </Typography>
                          </Box>
                        </Box>

                        <FormControl>
                          <FormLabel id="create-payment-radio-group">
                            Choose payment speed
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="create-payment-radio-group"
                            name="radio-buttons-group"
                            value={selectedServiceId}
                            onChange={(e) => {
                              setSelectedServiceId(e.target.value);

                              let serviceTemp = servicesList.find(i => i.id+"" === e.target.value);
                              if(serviceTemp){
                                setSelectedService(serviceTemp);
                                handleChange({ target: {
                                  name: "PaymentDate",
                                  value: dayjs(formData.Date).add(serviceTemp?.delivery_method?.delivery_speed, 'day')
                                }});
                                setFeeAmount(serviceFees[servicesList.indexOf(serviceTemp)]);
                              }

                            }}>
                            {
                              servicesList.filter(i => {
                                if(i?.delivery_method?.delivery_method === "INSTANT_TRANSFER"){
                                  if(dayjs(formData.Date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
                                    return true;
                                  }else {
                                    return false;
                                  }
                                }else {
                                  return true;
                                }
                              }).map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={item.id}
                                  disabled={item.delivery_method === "DOMESTIC_WIRE" || loadingServiceFeePrice}
                                  control={<Radio />} 
                                  label={
                                    <Box
                                      mt={"10px"}>
                                      <Box className="flexCenter_Row">
                                        <Typography
                                          variant='body2'>
                                          {handleDeliveryMethod(item?.delivery_method?.delivery_method) || <NA />}
                                        </Typography>&nbsp;
                                        {
                                          item.delivery_speed > 1
                                          &&
                                          <Chip
                                            size='small'
                                            sx={{ marginLeft: "5px" }}
                                            label={
                                              <>
                                                {item?.delivery_method?.delivery_speed}&nbsp;days
                                              </>
                                            }/>
                                        }
                                      </Box>
                                      {
                                        loadingServiceFeePrice
                                        ?
                                        <Skeleton
                                          variant="text" 
                                          />
                                        :
                                        <Typography
                                          variant='caption'
                                          color={"grey"}>
                                          Fee {handleShowAmount("USD", serviceFees[index])}
                                        </Typography>
                                      }
                                      {/* <Typography variant='caption' color={"grey"}>Free</Typography> */}
                                    </Box>
                                  }
                                  />

                              ))
                            }
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <LoadingButton
                        variant="contained"
                        disabled={selectedServiceId === null || loadingServiceFeePrice}
                        onClick={handleNextStep}
                        >
                        Continue
                      </LoadingButton>
                    </Box>
                </Container>
                : 
                  step === 6
                  // review & confirm screen
                  ?
                  <Container
                      maxWidth="sm">
                      <Box
                        className="flexCenter_Row"
                        mt={"20px"}>
                        <IconButton
                          onClick={handlePrevStep}>
                          <ArrowBackOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box
                        className="flex__Column"
                        gap={"30px"}>
                        <Typography
                          variant='h4'
                          textAlign={"center"}
                          mt={"20px"}>
                          Review & confirm
                        </Typography> 

                        <Box
                          className="flex__Column"
                          gap={"10px"}>
                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body2'
                              mb={"8px"}
                              fontWeight={500}>
                              Payment amount
                            </Typography>
                            <Typography
                              ml={"20px"}
                              variant='h6'>
                              {handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}
                            </Typography>
                          </Box>

                          <Divider />

                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body2'
                              mb={"8px"}
                              fontWeight={500}>Pay to</Typography>
                            <Box
                              ml={"20px"}
                              className="flexCenterSBRow">
                              <Box
                                className="flex__Column">
                                <Typography
                                  variant='caption'
                                  color={"grey"}>Business name</Typography>
                                <Typography
                                  variant='subtitle1'>
                                  {company.company_name}
                                </Typography>
                              </Box>
                              <Box
                                className="flex__Column">
                                <Typography
                                  variant='caption'
                                  color={"grey"}>Invoice #</Typography>
                                <Typography
                                  variant='subtitle1'>
                                  {(formData?.Invoice && formData?.Invoice !== "NA")
                                    ? "#" + formData?.Invoice
                                    : <i style={{ color: "grey" }}>No invoice #</i>}
                                  </Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Divider />

                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body2'
                              mb={"8px"}
                              fontWeight={500}>Pay from</Typography>
                            <Box
                              ml={"20px"}>
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Bank account
                              </Typography>
                              {
                                selectedBankAccount
                                &&
                                <Typography
                                  variant='subtitle1'>
                                  Bank account (...{selectedBankAccount?.account_number?.slice(-4)})
                                </Typography>
                              }
                            </Box>
                            <Box
                              ml={"20px"}>
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Debit date
                              </Typography>
                              <Typography
                                variant='subtitle1'>
                                {dayjs(formData.Date).format("MMM DD, YYYY")}
                              </Typography>
                            </Box>
                          </Box>

                          <Divider />

                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body2'
                              mb={"8px"}
                              fontWeight={500}>Business Receives</Typography>
                            <Box
                              ml={"20px"}>
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Bank transfer to
                              </Typography>
                              <Typography
                                variant='subtitle1'>
                                {company.company_name}'s primary account
                              </Typography>
                            </Box>
                            <Box
                              ml={"20px"}>
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Delivery date
                              </Typography>
                              <Typography
                                variant='subtitle1'>
                                {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                              </Typography>
                            </Box>
                          </Box>

                          <Divider />

                          {/* <Box
                            className="flex__Column">
                            <Typography
                              variant='caption'>Memo to vendor</Typography>
                            <Typography
                              variant='subtitle1'>No memo added</Typography>
                          </Box>
                          <Divider /> */}

                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body2'
                              mb={"8px"}
                              fontWeight={500}>Transaction fees</Typography>
                            <Box
                              ml={"20px"}
                              className="flexCenterSBRow">
                              <Typography
                                variant='subtitle1'>Total fee</Typography>
                              <Typography
                                variant='subtitle1'>
                                {handleShowAmount("USD", feeAmount)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <LoadingButton
                          variant="contained"
                          loading={loading}
                          onClick={handlePay}
                          >
                          Confirm and schedule payment
                        </LoadingButton>
                      </Box>
                  </Container>
                  :
                    step === 7
                    // scheduled screen
                    ?
                    <Container
                        maxWidth="sm">
                        <Box
                          width={"100%"}
                          margin={"0px 20px"}
                          className="flex__Column"
                          gap={"30px"}>
                          <Typography
                            variant='h4'
                            textAlign={"center"}
                            mt={"40px"}>
                            Payment scheduled
                          </Typography> 

                          <Box
                            className="flex__Column"
                            gap={"30px"}>
                            <Box
                              className="flex__Column">
                              <Typography
                                variant='body1'
                                fontWeight={500}>Payment summary</Typography>
                              <Typography
                                ml={"20px"}
                                variant='h6'>
                                {handleShowAmount(formData?.Currency, handleCalculateAmount(formData?.Currency, formData?.Price))}
                              </Typography>
                            </Box>
                            <Box
                              className="flex__Column"
                              gap={"5px"}>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Pay to business
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                  {company.company_name}
                                </Typography>
                              </Box>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Payment method
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                  Business checking 
                                </Typography>
                              </Box>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Debit date
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                  {dayjs(formData.Date).format("MMM DD, YYYY")}
                                </Typography>
                              </Box>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Delivery method
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                 {handleDeliveryMethod(selectedService?.delivery_method?.delivery_method) || <NA />} transfer to&nbsp;
                                 {company.company_name}'s primary account
                                </Typography>
                              </Box>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Delivery date
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                  {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                                </Typography>
                              </Box>
                              <Box  
                                className="flexCenter_Row">
                                <Typography 
                                  width={"50%"}
                                  color={"grey"}>
                                  Fees
                                </Typography>
                                <Typography 
                                  width={"50%"}>
                                  {handleShowAmount("USD", feeAmount)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <LoadingButton
                            variant="contained">
                            Schedule another payment
                          </LoadingButton>
                        </Box>
                    </Container>
                    :
                    <></>
      }

    </Box>
  )
}

export default GuestFlow;