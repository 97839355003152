import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Container,
  MenuItem,
  Modal, 
  Skeleton, 
  TextField, 
  ToggleButton, 
  ToggleButtonGroup, 
  Typography
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import { 
  countryList 
} from '../../../../components/utils/countryCodeList';
import { 
  stateList 
} from '../../../../components/utils/usStateCodeList';

import { 
  CreateVendor,
  GetVendorsByBusinessHandle,
  LinkPartyAsVendor, 
} from '../../../../redux/actions/pay/vendorsActions';
import {
  CreateVendorAccount, 
  UpdateVendorAccount
} from '../../../../redux/actions/pay/vendorBankAccountsActions';
import { 
  GetCompanyAllowedForInternationalPayments 
} from '../../../../redux/actions/dashboard/companiesActions';
import { 
  GetBeneficiaryRequirements 
} from '../../../../redux/actions/international/referenceActions';

import { 
  CLEAR_VENDORS_BY_BUSINESS_HANDLE_PAY
} from '../../../../redux/actionTypes';

import VendorBankAccountsModal from './VendorBankAccountsModal';
import VerifyBankAccountModal from './VerifyBankAccountModal';

import "./styles.scss";

const Data = {
  name: "",
  ach: "",
  wireRouting: "",
  account: "",
  accountHolderName: "",
  BankId: "",
  BankAccountTypeId: "",
  Address: "",
  City: "",
  State: "",
  Pincode: "",
  party_handle: ""
};

// const routingCodeTypeList = [
//   { label: "Aba", value: "aba" }, 
//   { label: "Bank code", value: "bank_code" },
//   { label: "Branch code", value: "branch_code" },
//   { label: "BSB code", value: "bsb_code" },
//   { label: "Clabe", value: "clabe" },
//   { label: "Cnaps", value: "cnaps" },
//   { label: "Ifsc", value: "ifsc" },
//   { label: "Institution number", value: "institution_no" },
//   { label: "Sort code", value: "sort_code" },
// ];

const BankDetailsLabel = {
  "acctNumber": "Account number",
  "iban": "Iban",
  "bicSwift": "Swift/Bic",
  "aba": "Aba",
  "bank_code": "Bank code",
  "branch_code": "Branch code",
  "bsb_code": "Bsb code",
  "clabe": "Clabe",
  "cnaps": "Cnaps",
  "ifsc": "Ifsc",
  "institution_no": "Institution number",
  "sort_code": "Sort code"
};

const NEWCreateVendorsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const state = useSelector(state => state.pay);
  const dashboard = useSelector(state => state.dashboard);
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [partyLoading, setPartyLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [partyLinking, setPartyLinking] = useState(false);
  const [mode, setMode] = useState(-1);

  const [vendorBankAccountsModalOpen, setVendorBankAccountsModalOpen] = useState(false);
  const [verifyBankAccountModalOpen, setVerifyBankAccountModalOpen] = useState(false);
  const [accountsModalUpdate, setAccountsModalUpdate] = useState(null);
  const [verifyModalUpdate, setVerifyModalUpdate] = useState(null);

  const [vendorId, setVendorId] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [banksList, setBanksList] = useState([]);
  const [bankAccountTypesList, setBankAccountTypesList] = useState([]);

  const [partiesList, setPartiesList] = useState([]);

  const [selectedParty, setSelectedParty] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [allowedForInterPmts, setAllowedForInterPmts] = useState(false);
  const [vendorRegion, setVendorRegion] = useState("domestic");

  const [bankPaymentTypes, setBankPaymentTypes] = useState([]);
  const [requiredBankFields, setRequiredBankFields] = useState([]);
  const [requiredBankErrors, setRequiredBankErrors] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
    setVendorId(null);
    setVendorRegion("domestic");
    setRequiredBankFields([]);
    setBankPaymentTypes([]);
    setPartiesList([]);
  };

  useEffect(() => {
    if(open) {
      dispatch({ type: CLEAR_VENDORS_BY_BUSINESS_HANDLE_PAY });
      setPartiesList([]);

      dispatch(GetCompanyAllowedForInternationalPayments(companyId, setLoading));

      if(update.defaultMode === 1){
        // mode = 1 - create vendor
        setMode(1);
      }else if(update.defaultMode === 3){
        // mode = 3 - add new bank detail
        setMode(update.defaultMode);
        setVendorId(update.vendorId);
      }else if(update.defaultMode === 4){
        // mode = 4 - edit bank detail
        setMode(update.defaultMode);
        setVendorId(update.vendorId);
        setFormData({
          ...formData,
          ach: update.ach || "",
          account: update.account || "",
        });
      } else {
        setVendorId(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatBanksList(state.banksList.records || []);
      formatBankAccountTypesList(state.bankAccountTypesList.records || []);
      formatPartiesList(state.vendorsByBusinessHandleList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  
  useEffect(() => {
    try {
      console.log(dashboard)
      setAllowedForInterPmts(dashboard.allowedForInternationalPayments);
    } catch (err) {}
  }, [dashboard]);

  const formatBanksList = (list) => {
    setBanksList(list);
  };

  const formatBankAccountTypesList = (list) => {
    setBankAccountTypesList(list);
  };

  const formatPartiesList = (list) => {
    setPartiesList(list);
  };

  async function handleGetVendorByHandle(handle) {
    let query = `&search=${handle}`;
    dispatch(GetVendorsByBusinessHandle(companyId, query, setPartyLoading));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleBankDetailsChange = (e, regexp) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (new RegExp(regexp).test(e.target.value)) {
      let temp = requiredBankErrors;
      temp = temp.filter(i => i !== e.target.name);
      setRequiredBankErrors(temp);
    } else { 
      let temp = requiredBankErrors;
      temp.push(e.target.name);
      setRequiredBankErrors(temp);
    }
  };  

  const handleNextStep = () => {
    setMode(mode + 1);
  }
  
  const handlePrevStep = () => {
    setMode(mode - 1);
  }

  const handleCustomStep = (step) => {
    setMode(step);
  }

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // create vendor
        if(vendorRegion === "domestic"){
          // domestic vendor
          let obj = {
            name: formData.name,
            contact_name: formData.contact,
            email: formData.email,
            phone: formData.phone,
            addr_address1: formData.Address,
            addr_city: formData.City,
            addr_state: formData.State,
            addr_zip5: formData.Pincode,
            is_international: false
          };
    
          dispatch(CreateVendor(companyId, obj, setLoading)).then(({ res, statusCode }) => {
            if(statusCode === 201) {
              console.log({ res, statusCode })
              handleGet(setLoading);
              handleNextStep();
              if(res.data.vendor_id) {
                setVendorId(res.data.vendor_id);
              } else {
                handleClose();
                throw new Error();
              }
            }
          });
        }
      }else if(mode === 3){
        // create vendor account
        let obj = {
          "ach_routing": formData.ach,
          "wire_routing": formData.wireRouting,
          "account_number": formData.account,
          "account_holder_name": formData.accountHolderName,
          // "ref_bank_id": formData.BankId || "",
          "ref_bank_acc_type_id": formData.BankAccountTypeId || "",
        };

        dispatch(CreateVendorAccount(companyId, vendorId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      }else if(mode === 4){
        // edit vendor account
        let obj = {
          "ach_routing": formData.ach,
          "wire_routing": formData.wireRouting,
          "account_number": formData.account,
          "account_holder_name": formData.accountHolderName,
        };

        dispatch(UpdateVendorAccount(companyId, vendorId, update.vendorBankAccountId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      }else if(mode === 5){
        // create international vendor 
        const routingCode = ["aba", "bank_code", "branch_code", 
          "bsb_code", "clabe", "cnaps", "ifsc", "institution_no", "sort_code"];

          
          const filteredRoutingCode = Object.entries(formData).filter(([key]) =>
            routingCode.includes(key)
        );
        console.log(filteredRoutingCode)
        
        let obj = {
          // vendor
          name: formData.CCName || "",
          contact_name: formData.contact,
          email: formData.CCEmail || "",
          beneficiaryCompanyName: formData.CCName || "",
          beneficiaryAddress: formData.CCAddress || "",
          beneficiaryCity: formData.CCCity || "",
          beneficiaryStateOrProvince: formData.CCState || "",
          beneficiaryPostcode: formData.CCPincode || "",
          beneficiaryDateOfBirth: "", // date of registration
          beneficiaryCountry: formData.CCVendorCountry || "",
          beneficiaryIdentificationType: "",
          beneficiaryIdentificationValue: "",
          beneficiaryEntityType: "company",
          businessNature: "",
          companyWebsite: "",

          // bank
          bankAccountHolderName: formData.CCBankHolderName || "",
          bankName: formData.CCBankName || "",
          bankAddress: "",
          bankCountry: formData.CCVendorCountry || "",
          bankAccountType: "",
          // bankAccountHolderName: formData.CCBankHolderName || "",
          // bankName: formData.CCBankName || "",
          // bankAddress : formData.CCBankAddress || "",
          // bankCountry: formData.CCVendorCountry || "",
          // bankAccountType: formData.CCBankAccType || "",
          currency: formData.CCCurrency || "",

          routingCodeType1: filteredRoutingCode.length === 1 ? filteredRoutingCode[0][0] : "",
          routingCodeValue1: filteredRoutingCode.length === 1 ? filteredRoutingCode[0][1] : "",
          routingCodeType2: filteredRoutingCode.length === 2 ? filteredRoutingCode[1][0] : "",
          routingCodeValue2: filteredRoutingCode.length === 2 ? filteredRoutingCode[1][1] : "",
          accountNumber: formData.acctNumber || "",
          bicSwift: formData.bicSwift || "",
          iban: formData.iban || "",

          paymentTypes: bankPaymentTypes,
          is_international: true
        };
  
        dispatch(CreateVendor(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if(statusCode === 201) {
            handleGet(setLoading);
            handleClose();
          }
        });
      }else{
        throw new Error();
      }
    } catch(err) {
      console.log(err);
      toast.error("Something went wrong!");
    }
  };

  // eslint-disable-next-line no-unused-vars
  function handleCheckCurrencyCountryPair(){
    let query = "";
    query += `&currency=${formData.CCCurrency}`;
    query += `&bank_account_country=${formData.CCVendorCountry}`;
    query += `&beneficiary_country=${formData.CCVendorCountry}`;

    dispatch(GetBeneficiaryRequirements(companyId, query, setLoading)).then(({ res, statusCode }) => {
      if(statusCode === 200){
        if(res.data.details.length === 0){
          // not allowed
          setFormData({
            ...formData,
            CCCurrency: "",
            CCVendorCountry: ""
          });
          toast.error("Currency not allowed for selected country");
        } else {
          // allowed
          handleCustomStep(5);
          HandleFormatRequiredFields(res.data.details);
        }
      }
    })
  };

  function HandleFormatRequiredFields(list){
    let company_requirements = list.filter(i => i.beneficiaryEntityType === "company");
          
    // payment types
    let temp = [];
    // regular check 
    if(company_requirements.find(i => i.paymentType === "regular")){
      temp.push("regular")
    };
    // priority check
    if(company_requirements.find(i => i.paymentType === "priority")){
      temp.push("priority")
    };
    setBankPaymentTypes(temp);
    
    // combined obj
    const combinedObject = company_requirements.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
    
    const allowedKeys = ["acctNumber", "iban", "bicSwift", "aba", "bank_code", "branch_code", 
      "bsb_code", "clabe", "cnaps", "ifsc", "institution_no", "sort_code"];
    
    const filterObjectKeys = (obj, allowedKeys) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([key]) => allowedKeys.includes(key))
      );
    };
    const filteredObj = filterObjectKeys(combinedObject, allowedKeys);
    console.log(filteredObj);
    
    setRequiredBankFields(filteredObj);
  };

  // ALTCARD party object
//   {
//     "id": "406403fd-55cc-4aae-b0fc-66bcf0dfffe7",
//     "customer_party_id": "6861854c-99dc-4646-b72c-71cab75c96d8",
//     "legal_name": "Party 2",
//     "trading_name": "Party 2",
//     "logo_url": "Santacruz",
//     "tax_id": "123423123",
//     "tax_id_type": "ein",
//     "incorporation_date": "2025-01-06",
//     "address": "Santacruz",
//     "city": "New York",
//     "state": "ID",
//     "country": "US",
//     "pincode": "22030",
//     "contact_name": "Michael Saylor",
//     "email": "mike@dynatech.com",
//     "domain": "dynatech.com",
//     "verification_level": "L2",
//     "entity_type": "Company",
//     "created_by": "23bf50c1-1779-4fdd-9e72-7d86e4c270cd",
//     "is_active": true,
//     "party_handle": "party-2",
//     "createdAt": "2025-01-07T07:25:08.168Z",
//     "updatedAt": "2025-01-07T07:25:08.168Z",
//     "deletedAt": null
// }

  function handleLinkPartyAsVendor(party){
    setSelectedParty(party);
    // check no. of bank accounts that exists
    if(party.party_bank_accounts.length === 1){
      setVerifyModalUpdate(party.party_bank_accounts[0]);
      setVerifyBankAccountModalOpen(!verifyBankAccountModalOpen);
    } else if (party.party_bank_accounts.length > 1){
      setAccountsModalUpdate(party);
      setVendorBankAccountsModalOpen(!vendorBankAccountsModalOpen);
    } else {
      toast.error("Invalid party");
    }

    // dispatch(LinkPartyAsVendor(companyId, formData, setPartyLinking)).then(() => {
    //   handleGet(setPartyLinking).then(() => {
    //     handleClose();
    //   })
    // })
  };

  const handleBankAccountVerify = (bank) => {
    setVerifyModalUpdate(bank);
    setVerifyBankAccountModalOpen(!verifyBankAccountModalOpen);
  };  

  const handleCheckBankDetails = (form, bank) => {
    if(form.ach_routing === verifyModalUpdate.routing_code_value_1 && form.account_number === verifyModalUpdate.account_number){
      let obj = {
        ...selectedParty,
        party_bank_accounts: [verifyModalUpdate]
      };
      dispatch(LinkPartyAsVendor(companyId, obj, setPartyLinking)).then(() => {
        handleGet(setPartyLinking).then(() => {
          handleClose();
          setVerifyBankAccountModalOpen(false);
          setVendorBankAccountsModalOpen(false);
        })
      });
    } else {
      toast.error("Invalid bank details");
      setVerifyModalUpdate(null);
      setVerifyBankAccountModalOpen(false);
    }
  };  

  return (
    <>
    <VendorBankAccountsModal
      open={vendorBankAccountsModalOpen}
      setOpen={setVendorBankAccountsModalOpen}
      title={"Select vendor bank account"}
      update={accountsModalUpdate}
      handleGet={() => {}}
      handleVerify={handleBankAccountVerify}
      />

    <VerifyBankAccountModal
      open={verifyBankAccountModalOpen}
      setOpen={setVerifyBankAccountModalOpen}
      title={"Verify bank details"}
      update={verifyModalUpdate}
      handleGet={() => {}}
      handleCheckDetails={handleCheckBankDetails}
      />

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >


      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={mode === 5
              ? () => handleCustomStep(1)
              : (mode === 3 || mode === 4)
                ? handleClose 
                : mode === 1 
                  ? handleClose 
                  : handlePrevStep}
            title={title} 
            />

          {
            mode === 1
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Column"
                gap={"15px"}
                mb={"20px"}>
                <Typography
                  variant='h4'
                  textAlign={"center"}>
                  Add vendor
                </Typography>
                
                <Typography
                  variant='subtitle2'
                  color={"grey"}>
                  Search by party ID
                </Typography>

                <TextField 
                  label="Party ID"
                  fullWidth
                  name='party_handle'
                  placeholder='Start entering party ID'
                  value={formData.party_handle}
                  onChange={(e) => {
                    handleGetVendorByHandle(e.target.value);
                    handleChange(e);
                  }}
                  inputProps={{
                    maxLength: 100
                  }}
                  />

                {
                  formData?.party_handle?.length > 2
                  ?
                  <Typography
                    variant='subtitle2'
                    textAlign={"center"}
                    color={"grey"}>
                    Matching vendors
                  </Typography>
                  :
                  <Typography
                    variant='subtitle2'
                    textAlign={"center"}
                    color={"grey"}>
                    OR
                  </Typography>
                }

                {
                  formData?.party_handle?.length > 2
                  &&
                  <Box
                    className="flex__Column"
                    width={"100%"}
                    gap={"15px"}
                    sx={{ 
                      padding: "10px",
                      overflow: "auto",
                      height: "calc(100vh - 270px)"
                    }}>
                    {
                      partyLoading
                      ?
                      <Box
                        width={"100%"}
                        className="flex__Column"
                        gap={"10px"}>
                        <Skeleton
                          variant='rectangular'
                          sx={{ height: "120px", borderRadius: "16px" }}
                          />
                        <Skeleton
                          variant='rectangular'
                          sx={{ height: "120px", borderRadius: "16px" }}
                          />
                      </Box>
                      :
                      partiesList.map((party, partyIndex) => (
                        <Box
                          key={partyIndex}
                          className="flex__Column party"
                          gap={"10px"}>
                          <Box
                            className="flexCenterSBRow">
                            <Box>
                              <Typography
                                className='flexCenterSBRow'
                                variant='subtitle2'
                                fontWeight={600}>
                                {party.party_handle || <NA />}
                              </Typography>
                              <Typography
                                className='flexCenterSBRow'>
                                {party.legal_name || <NA />}
                              </Typography>
                              <Typography
                                className='flexCenterSBRow'
                                variant='caption'>
                                {party.address || <NA />},&nbsp;{party.city || <NA />},&nbsp;{party.state || <NA />}
                              </Typography>
                              <Typography
                                className='flexCenterSBRow'
                                variant='caption'>
                                {party.country_code || <NA />},&nbsp;{party.pincode || <NA />}
                              </Typography>
                            </Box>
                            {/* <Box>
                              <Typography
                                variant='subtitle1'
                                color={"grey"}
                                mr={"20px"}>
                                {party.verification_level || <NA />}
                              </Typography>
                            </Box> */}
                          </Box>
                          <Box>
                            <Typography
                              className='flexCenterSBRow'>
                              Bank accounts: {party.party_bank_accounts.length}
                            </Typography>
                          </Box>
                          <Box 
                            className="flexCenterFERow">
                            <Box>
                              <LoadingButton
                                variant="contained"
                                disabled={party.party_bank_accounts.length < 1}
                                onClick={() => handleLinkPartyAsVendor(party)}>
                                Add
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                }


                {
                  formData?.party_handle?.length <= 2
                  &&
                  allowedForInterPmts
                  &&
                  <ToggleButtonGroup
                    color="primary"
                    value={vendorRegion}
                    exclusive
                    onChange={(e) => setVendorRegion(e.target.value)}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value={"domestic"}
                      sx={{ width: "150px" }}>
                      Domestic
                    </ToggleButton>
                    <ToggleButton 
                      value={"international"}
                      sx={{ width: "150px" }}>
                      International
                    </ToggleButton>
                  </ToggleButtonGroup>
                }

                {
                  formData?.party_handle?.length <= 2
                  &&
                  vendorRegion === "domestic"
                  ?
                  <>
                    <TextField 
                      label="Vendor's business name *"
                      fullWidth
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />

                    <TextField 
                      label="Vendor's contact name"
                      fullWidth
                      name='contact'
                      value={formData.contact}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />

                    <Box
                      width={"100%"}
                      className="flexCenter_Row"
                      gap="15px">
                      <TextField 
                        label="Email address"
                        fullWidth
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />

                      <TextField 
                        label="Phone number"
                        fullWidth
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />
                    </Box>
                    <Box
                      width={"100%"}
                      className="flexCenter_Row"
                      gap="15px">
                      <TextField 
                        label="Address"
                        fullWidth
                        name="Address"
                        value={formData.Address}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 128
                        }}
                        />
                      <TextField 
                        label="City"
                        fullWidth
                        name="City"
                        value={formData.City}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 128
                        }}
                        />
                    </Box>
                    <Box
                      width={"100%"}
                      className="flexCenter_Row"
                      gap="15px">
                      <TextField 
                        select
                        label="State"
                        fullWidth
                        name="State"
                        value={formData.State}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 128
                        }}
                        >
                        {
                          stateList.map((state, stateIndex) => (
                            <MenuItem
                              key={stateIndex}
                              value={state.code}>
                              <Typography>
                                {state.name}
                              </Typography>
                            </MenuItem>
                          ))
                        }
                      </TextField>
                      <TextField 
                        label="Postal code"
                        fullWidth
                        type='number'
                        name="Pincode"
                        value={formData.Pincode}
                        onChange={handleChange}
                        />
                    </Box>

                    <LoadingButton
                      variant='contained'
                      fullWidth
                      disabled={!(formData.name !== "" && formData.email !== "" && formData.Pincode !== "" &&
                        formData.Address !== "" && formData.City !== "" && formData.State !== ""
                      )}
                      loading={loading}
                      onClick={handleSubmit}>
                      Continue
                    </LoadingButton>
                  </>
                  :
                    vendorRegion === "international"
                    ?
                      <>
                        <TextField 
                          select
                          label="Vendor's country *"
                          fullWidth
                          name='CCVendorCountry'
                          value={formData.CCVendorCountry}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                          >
                          {
                            countryList.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.code}>
                                {item.name}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                        <TextField 
                          select
                          label="Currency *"
                          fullWidth
                          name='CCCurrency'
                          value={formData.CCCurrency}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                          >
                          {
                            JSON.parse(localStorage.getItem("CurrencyList"))?.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.code}>
                                {item.name} ({item.code})
                              </MenuItem>
                            ))
                          }
                        </TextField>
                        <LoadingButton
                          variant='contained'
                          fullWidth
                          loading={loading}
                          disabled={!(formData.CCVendorCountry !== "" && formData.CCCurrency !== "")}
                          onClick={handleCheckCurrencyCountryPair}>
                          Continue
                        </LoadingButton>
                      </>
                    :
                    <></>
                }

               
              </Box>
            </Container>
            :
              mode === 2
              ?
              <Container 
                maxWidth="sm">
                <Box
                  className="flexCenter_Column"
                  gap={"40px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}>
                    How would&nbsp;
                    <span style={{ color: "darkgrey" }}>{formData.name}</span>
                    &nbsp;like to receive payments?
                  </Typography>

                  <Typography
                    textAlign={"center"}>
                    Vendors don't need to sign up to get paid and it's free every time.
                  </Typography>

                  <Box
                    className="vendorset__bank flexCenter_Row" 
                    gap={"20px"}
                    onClick={handleNextStep}>
                    <AccountBalanceOutlinedIcon 
                      fontSize='large'/>
                    <Box>
                      <Typography 
                        variant='subtitle1'
                        fontWeight={600}>
                        Bank transfer (ACH)
                      </Typography>
                      <Typography
                        variant='body2'>
                        Deposit funds into your vendor’s bank account. (Free)
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    fullWidth
                    onClick={handleClose}>
                    Skip for now
                  </Button>
                </Box>

              </Container>
              :
                mode === 3
                ?
                <Container
                  maxWidth="sm">
                  <Box
                    className="flexCenter_Column"
                    gap={"20px"}>
                    <Typography
                      variant='h4'
                      textAlign={"center"}
                      mt={"40px"}>
                      Add&nbsp;
                      <span style={{ color: "darkgrey" }}>{formData.name}</span>
                      &nbsp;bank details
                    </Typography>

                    <Box
                      className="flexCenterSBRow"
                      width={"100%"}
                      gap={"20px"}>
                      {/* <TextField 
                        select
                        label="Bank"
                        fullWidth
                        name="BankId"
                        disabled={mode === 2}
                        value={formData.BankId}
                        onChange={handleChange}>
                        {
                          banksList.length === 0
                          ?
                          <MenuItem
                            disabled>
                            No bank found
                          </MenuItem>
                          :
                            banksList.map((bank, bankIndex) => (
                              <MenuItem
                                key={bankIndex}
                                value={bank.id}>
                                {bank.bank || <NA />}
                              </MenuItem>
                            ))
                        }
                      </TextField> */}
                      <TextField 
                        select
                        label="Account type"
                        fullWidth
                        name="BankAccountTypeId"
                        disabled={mode === 2}
                        value={formData.BankAccountTypeId}
                        onChange={handleChange}>
                        {
                          bankAccountTypesList.length === 0
                          ?
                          <MenuItem
                            disabled>
                            No account type found
                          </MenuItem>
                          :
                            bankAccountTypesList.map((accountType, accountTypeIndex) => (
                              <MenuItem
                                key={accountTypeIndex}
                                value={accountType.id}>
                                {accountType.type || <NA />}
                              </MenuItem>
                            ))
                        }
                      </TextField>
                    </Box>

                    <TextField 
                      label="Vendor's account holder name"
                      fullWidth
                      name="accountHolderName"
                      value={formData.accountHolderName}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 256
                      }}
                      />

                    <TextField 
                      label="Vendor's routing number (ACH)*"
                      fullWidth
                      name='ach'
                      value={formData.ach}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />
{/* 
                    <TextField 
                      label="Vendor's wire routing number"
                      fullWidth
                      name='wireRouting'
                      value={formData.wireRouting}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 9
                      }}
                      /> */}

                    <TextField 
                      label="Vendor's account number*"
                      fullWidth
                      name='account'
                      value={formData.account}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />

                    {/* <Button
                      fullWidth
                      variant='contained'>
                      Save and add bill
                    </Button> */}

                    <LoadingButton
                      loading={loading}
                      fullWidth
                      disabled={!(formData.ach !== "" && formData.account !== "" && 
                        formData.accountHolderName !== "" && formData.BankAccountTypeId !== "")}
                      onClick={handleSubmit}>
                      Save and close
                    </LoadingButton>

                    <Typography
                      variant='body2'
                      textAlign={"center"}>
                      We'll save these details for future payments to this vendor. 
                      You can always edit them later from your vendor's contact page.
                    </Typography>
                  </Box>
                </Container>
                : 
                  mode === 4
                  ?
                  <Container
                    maxWidth="sm">
                    <Box
                      className="flexCenter_Column"
                      gap={"40px"}>
                      <Typography
                        variant='h4'
                        textAlign={"center"}
                        mt={"40px"}>
                        Edit&nbsp;
                        <span style={{ color: "darkgrey" }}>{formData.name}</span>
                        &nbsp;bank details
                      </Typography>
                        
                      <TextField 
                        label="Vendor's account holder name"
                        fullWidth
                        name="accountHolderName"
                        value={formData.accountHolderName}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 256
                        }}
                        />

                      <TextField 
                        label="Vendor's routing number (ACH)*"
                        fullWidth
                        name='ach'
                        value={formData.ach}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />

                      <TextField 
                        label="Vendor's account number*"
                        fullWidth
                        name='account'
                        value={formData.account}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />

                      <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={loading}
                        disabled={mode === 4
                          ? !(formData.ach !== "" && formData.account !== "" && formData.accountHolderName !== "")
                          : !(formData.ach !== "" && formData.account !== "" && formData.accountHolderName !== "" &&
                            formData.BankId !== "" && formData.BankAccountTypeId !== "")
                        }
                        onClick={handleSubmit}>
                        Save
                      </LoadingButton>
                    </Box>
                  </Container>
                  :
                    mode === 5
                    ?
                    <Container
                      maxWidth="sm">
                      <Box
                        className="flexCenter_Column"
                        gap={"15px"}
                        mb={"20px"}>

                        <Typography
                          variant='h4'
                          textAlign={"center"}
                          mt={"40px"}>
                          Vendor details
                        </Typography>

                        <TextField 
                          label="Vendor's business name *"
                          fullWidth
                          name='CCName'
                          value={formData.CCName}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                          />
                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Vendor's contact name *"
                            fullWidth
                            name='contact'
                            value={formData.contact}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 100
                            }}
                            />
                          <TextField 
                            label="Vendor's email *"
                            fullWidth
                            name='CCEmail'
                            value={formData.CCEmail}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 100
                            }}
                            />
                        </Box>

                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Address *"
                            fullWidth
                            name="CCAddress"
                            value={formData.CCAddress}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                          <TextField 
                            label="City *"
                            fullWidth
                            name="CCCity"
                            value={formData.CCCity}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                      </Box>

                      <Box
                        width={"100%"}
                        className="flexCenter_Row"
                        gap="15px">
                        <TextField 
                          label="State *"
                          fullWidth
                          name="CCState"
                          value={formData.CCState}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 128
                          }}
                          />
                        <TextField 
                          label="Postal code *"
                          fullWidth
                          type='number'
                          name="CCPincode"
                          value={formData.CCPincode}
                          onChange={handleChange}
                          />
                        </Box>

                        <Typography
                          variant='body1'
                          color="grey"
                          fontWeight={600}
                          sx={{ width: "100%", mt: "10px" }}
                          textAlign={"left"}>
                          Bank details
                        </Typography>

                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Bank account holder name"
                            fullWidth
                            name="CCBankHolderName"
                            value={formData.CCBankHolderName}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                          <TextField 
                            label="Bank name"
                            fullWidth
                            name="CCBankName"
                            value={formData.CCBankName}
                            onChange={handleChange}
                            />
                        </Box>

                        {
                          Object.entries(requiredBankFields).map((field, fieldIndex) => (
                            <TextField 
                              label={BankDetailsLabel[field[0]]}
                              fullWidth
                              name={field[0]}
                              value={formData[field[0]]}
                              onChange={(e) => {
                                handleBankDetailsChange(e, field[1])
                              }}
                              error={requiredBankErrors.includes(field[0])}
                              helperText={
                                requiredBankErrors.includes(field[0])
                                && 
                                `Enter a valid value`
                              }
                              />
                          ))
                        }

                        <LoadingButton
                          variant='contained'
                          fullWidth
                          loading={loading}
                          disabled={!(formData.CCName !== "" && formData.contact !== "" &&
                            formData.CCEmail !== "" && formData.CCAddress !== "" &&
                            formData.CCCity !== "" && formData.CCState !== "" &&
                            formData.CCBankHolderName !== "" && formData.CCBankName !== "" &&
                            formData.CCBankAddress !== "" &&
                            formData.CCPincode !== "" && requiredBankErrors.length === 0)}
                          onClick={handleSubmit}>
                          Continue
                        </LoadingButton>  

                       

                        {/* <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Bank address"
                            fullWidth
                            name="CCBankAddress"
                            value={formData.CCBankAddress}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                          <TextField 
                            select
                            label="Bank account type"
                            fullWidth
                            name="CCBankAccType"
                            value={formData.CCBankAccType}
                            onChange={handleChange}
                            >
                            {
                              [{ label: "Checking", value: "checking" }, 
                                { label: "Savings", value: "savings" }].map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))
                            }
                          </TextField>
                        </Box>
                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            select
                            label="Routing code type"
                            fullWidth
                            name="CCRoutingCodeType1"
                            value={formData.CCRoutingCodeType1}
                            onChange={handleChange}
                            >
                            {
                              routingCodeTypeList.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))
                            }
                          </TextField>
                          <TextField 
                            label="Routing code value"
                            fullWidth
                            name="CCRoutingCodeValue1"
                            value={formData.CCRoutingCodeValue1}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                        </Box>
                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Bank account"
                            fullWidth
                            name="CCAccount"
                            value={formData.CCAccount}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                        </Box>
                        <Box
                          width={"100%"}
                          className="flexCenter_Row"
                          gap="15px">
                          <TextField 
                            label="Swift"
                            fullWidth
                            name="CCSwift"
                            value={formData.CCSwift}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                          <TextField 
                            label="Iban"
                            fullWidth
                            name="CCIban"
                            value={formData.CCIban}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 128
                            }}
                            />
                        </Box> */}
                      </Box>
                    </Container>
                    :
                    <></>
          }
        </Box>
      </Box>
    </Modal>
    </>
  )
}

export default NEWCreateVendorsModal;