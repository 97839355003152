import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";

export const GetDetailedRates = (companyId, query, cancellable, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.getDetailedRates(companyId, query, cancellable);
    res = data;
    statusCode = status;
    if (status === 200) {

    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};