import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePlaidLink } from 'react-plaid-link';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  Modal, 
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';

import { 
  CreateCompanyBankAccount, 
  CreatePlaidLinkTokenCompanyBankAccount, 
  CreateVerificationCompanyBankAccount, 
  ExchangePlaidPublicTokenCompanyBankAccount, 
  GetPlaidAuthCompanyBankAccount, 
  VerifyCompanyBankAccount, 
} from '../../../redux/actions/pay/companyBankAccountsActions';
import { 
  GetCompanyInfo 
} from '../../../redux/actions/dashboard/companyRoleActions';

import {
  CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY 
} from '../../../redux/actionTypes';

import "./styles.scss";

const Data = {
  AchRouting: "",
  AccNumber: "",
  AccHolderName: "",
  // BankId: "",
  BankAccountTypeId: ""
};

const AddCompanyBankAccountModal = ({
  open, 
  setOpen,
  title, 
  update, 
  handleGet
}) => {
  const navigate = useNavigate();
	const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [mode, setMode] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [banksList, setBanksList] = useState([]);
  const [bankAccountTypesList, setBankAccountTypesList] = useState([]);
  
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [plaidAccessToken, setPlaidAccessToken] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setPlaidLinkToken(null)
  };

  useEffect(() => {
    if(open){
      setStep(1);
      dispatch({ type: CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY });
      if(update.defaultMode === 1){
        // mode = 1 - select account addition mode
        setMode(1);
      } else if (update.defaultMode === 2){
        // mode = 2 - enter bank details
        setMode(2);
      } else if (update.defaultMode === 3){
        // mode = 3 - enter verification code
        setMode(3);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatBanksList(state.banksList.records || []);
      formatBankAccountTypesList(state.bankAccountTypesList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatBanksList = (list) => {
    setBanksList(list);
  };

  const formatBankAccountTypesList = (list) => {
    setBankAccountTypesList(list);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleNextStep = () => {
    setStep(step + 1);
  };

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 2){
        // bank account details
        let obj = {
          "ach_routing": formData.AchRouting || "",
          "account_number": formData.AccNumber || "",
          "account_type_id": formData.BankAccountTypeId || "",
          "account_holder_name": formData.AccHolderName || "",
          "from_plaid": false      
        };
        dispatch(CreateCompanyBankAccount(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if (statusCode === 201){
            dispatch(CreateVerificationCompanyBankAccount(companyId, { "bank_account_id": res.data.id }, setLoading)).then(({ res, statusCode }) => {
              if (statusCode === 201) {
                handleGet();
                handleNextStep();
              }
            });
          };
        });
      } else if (mode === 3){
        // verification code
        let obj = {
          "code": formData.Code,
          "bank_account_id": update?.bank_account_id
        };
        dispatch(VerifyCompanyBankAccount(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if (statusCode === 201) {
            handleNextStep();
            handleGet();
            dispatch(GetCompanyInfo(companyId, setLoading)).then(async ({ res, statusCode }) => {
              console.log(res, statusCode)
              if(statusCode === 200){
                if(res.data.company_bank_account_exists === false){
                  navigate("/add-bank-account");
                } else if (res.data.company_has_subs === false){
                  navigate("/add-subscription");
                } else {
                  navigate("/");
                }
              } else {
      
              }
            });
          }
        });
      } else {

      }
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleCreateLinkToken = () => {
    dispatch(CreatePlaidLinkTokenCompanyBankAccount(companyId, setPlaidLoading)).then(({res, statusCode}) => {
      if(statusCode === 201){
        console.log(res); 
        setPlaidLinkToken(res.data.link_token);
      };  
    });
  };

  const handleExchangePublicToken = (public_token, metadata) => {
    let obj = {
      "public_token": public_token
    };

    dispatch(ExchangePlaidPublicTokenCompanyBankAccount(companyId, obj, setPlaidLoading)).then(({res, statusCode}) => {
      if(statusCode === 201){
        console.log(res); 
        setPlaidAccessToken(res.data.accessToken);
        handleGetAuthInfo(res.data.accessToken, metadata)
      }; 
    });
  };

  const handleGetAuthInfo = (access_token, metadata) => {
    let obj = {
      "access_token": access_token,
      "account_id": metadata.account_id
    };

    dispatch(GetPlaidAuthCompanyBankAccount(companyId, obj, setPlaidLoading)).then(({res, statusCode}) => {
      if(statusCode === 201){
        console.log(res); 
        handleCreateBankAccountFromPlaid(res.data?.accounts[0], res.data?.numbers?.ach[0]);
      }; 
    });
  };

  const handleCreateBankAccountFromPlaid = (accountType, account) => {
    // bank account details from plaid
    let obj = {
      "ach_routing": account.routing || "",
      "account_number": account.account || "",
      "account_type_id": bankAccountTypesList.find(i => i.type.toLowerCase() === accountType.subtype.toLowerCase())?.id,
      "from_plaid": true          
    };
    dispatch(CreateCompanyBankAccount(companyId, obj, setPlaidLoading)).then(({ res, statusCode }) => {
      if (statusCode === 201){
        handleGet();
        dispatch(GetCompanyInfo(companyId, setPlaidLoading)).then(async ({ res, statusCode }) => {
          console.log(res, statusCode)
          if(statusCode === 200){
            if(res.data.company_bank_account_exists === false){
              navigate("/add-bank-account");
            } else if (res.data.company_has_subs === false){
              handleNextStep();
            } else {
              navigate("/");
            }
          } else {
  
          }
        });
      };
    });
  };

  // ---------------- plaid ----------------
  const { open: plaidOpen, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: (publicToken, metadata) => {
      // Call your backend API to exchange the public token
      console.log(publicToken, metadata); 
      handleExchangePublicToken(publicToken, metadata);
      // onSuccess(publicToken, metadata);
    },
  });

  useEffect(() => {
    if(plaidLinkToken && ready) plaidOpen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaidLinkToken, ready]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >

      <Box
        className="fullpage__modal animate__animated animate__fadeIn">
                 
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        {
          mode === 1
          // enter bank account details
          ?
            step === 1
            ?
            <Container 
              maxWidth="sm">
                <Box
                  className="flexCenterCenterColumn"
                  gap={"40px"}
                  height={"calc(100vh - 200px)"}>
                  <Typography
                    textAlign={"center"}
                    variant={"h4"}
                    marginBottom={"-30px"}>
                    Connect a bank account
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    variant={"body1"}
                    color={"grey"}>
                    Choose how to connect and verify your bank account.
                  </Typography>

                  <Box
                    className="verify__card"
                    onClick={
                      plaidLoading 
                      ? () => {}
                      : () => handleCreateLinkToken()
                    }>
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      <Typography
                        variant={"h6"}>
                        Verify using Plaid log-in
                      </Typography>
                      {
                        plaidLoading
                        &&
                        <CircularProgress 
                          size={25}/> 
                      }
                    </Box>
                    <Typography
                      variant={"body2"}
                      color={'grey'}>
                      Securely log into your bank to verify instantly* and start scheduling transfers right away.
                    </Typography>
                  </Box>
                  <Box
                    className="verify__card"
                    onClick={
                      plaidLoading 
                      ? () => {}
                      : () => setMode(2)
                    }>
                    <Typography
                      variant={"h6"}>
                      Verify with penny deposit
                    </Typography>
                    <Typography
                      variant={"body2"}
                      color={'grey'}>
                      Receive a penny deposit in your bank account instantly* or 2-3 business days. Verify using the 4-character code in the description.
                    </Typography>
                  </Box>
                  <Typography
                    variant='caption'
                    color={"grey"}>
                    * Instant verification depends upon your bank account
                  </Typography>
                </Box>
            </Container>
            :
              step === 2
              ?
              <Container
                maxWidth="sm">
                <Box 
                  className="flexCenterCenterColumn"
                  gap={"20px"}
                  sx={{ height: "calc(100vh - 90px)" }}>
                  <VerifiedTwoToneIcon 
                    color='success'
                    sx={{ width: "50px", height: "50px" }}/>
                  <Typography
                    variant='subtitle1'>
                    Bank account linked successfully
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={() => navigate("/add-subscription")}>
                    Continue
                  </Button>
                </Box>
              </Container>     
              :
              <></>
          :
            mode === 2
            // enter bank account details
            ?
              step === 1
              ?
              <Container
                maxWidth="sm">
                <Box 
                  className="flex__Column"
                  gap={"20px"}
                  pt="20px">

                  <Box
                    className="flexCenterSBRow"
                    gap={"20px"}>
                    <TextField
                      select
                      label="Account type"
                      fullWidth
                      name="BankAccountTypeId"
                      value={formData.BankAccountTypeId}
                      onChange={handleChange}>
                      {
                        bankAccountTypesList.length === 0
                        ?
                        <MenuItem
                          disabled>
                          No account type found
                        </MenuItem>
                        :
                          bankAccountTypesList.map((accountType, accountTypeIndex) => (
                            <MenuItem
                              key={accountTypeIndex}
                              value={accountType.id}>
                              {accountType.type || <NA />}
                            </MenuItem>
                          ))
                      }
                    </TextField>
                  </Box>
                  <TextField 
                    label="Account holder name"
                    fullWidth
                    name="AccHolderName"
                    value={formData.AccHolderName}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 256
                    }}
                    />
                  <TextField 
                    label="ACH routing number"
                    fullWidth
                    name="AchRouting"
                    value={formData.AchRouting}
                    onChange={handleChange}
                    />
                  <TextField 
                    label="Account number"
                    fullWidth
                    name="AccNumber"
                    type='number'
                    value={formData.AccNumber}
                    onChange={handleChange}
                    />
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    disabled={!(formData.AccHolderName !== "" && formData.AchRouting !== "" && 
                      formData.AccNumber !== "" && formData.BankAccountTypeId !== "")}
                    onClick={handleSubmit}>
                    Continue
                  </LoadingButton>
                </Box>
              </Container>
              :
                step === 2
                ?
                <Container
                  maxWidth="sm">
                  <Box 
                    className="flexCenterCenterColumn"
                    gap={"20px"}
                    sx={{ height: "calc(100vh - 90px)" }}>
                    <Typography
                      variant='subtitle2'>
                      We've initiated a micro-deposit of $0.01 to verify your bank account. 
                    </Typography>

                    <Typography
                      textAlign={"left"}>
                      To complete the verification:<br/>
                      1. Log into your online bank account<br/>
                      2. Copy 4-digit verification code in deposit description<br/>
                      3. Return to the Lusid "Add Bank Account" screen<br/>
                      4. Enter the 4-digit code to confirm account details<br/>
                    </Typography>

                    <Typography
                      textAlign={"center"}
                      variant='caption'
                      color={"grey"}>
                      This small deposit confirms you have authorized access to the bank account and completes the verification process.
                    </Typography>
                    <Button
                      variant='contained'
                      onClick={handleClose}>
                      Close
                    </Button>
                  </Box>
                </Container>
                :
                <></>
            :
              mode === 3
              // ORUM - enter verification code
              ?
                step === 1
                ?
                <Container 
                  maxWidth="sm">
                  <Box
                    className="flexCenterCenterColumn"
                    gap={"20px"}
                    width={"350px"}
                    margin={"auto"}
                    height={"calc(100vh - 200px)"}>
                    <Typography
                      textAlign={"center"}
                      variant={"subtitle1"}>
                      Please enter the 4-digit code in the description of that deposit
                    </Typography>
                    <TextField 
                      fullWidth
                      placeholder='Enter 4-digit code'
                      type='password'
                      name='Code'
                      value={formData.Code}
                      onChange={handleChange}
                      />
                    <LoadingButton
                      sx={{ width: "100%" }}
                      variant="contained"
                      loading={loading}
                      disabled={!(formData.Code !== "")}
                      onClick={handleSubmit}>
                      Submit
                    </LoadingButton>
                  </Box>
                </Container>
                :
                  step === 2
                  ?
                  <Container
                    maxWidth="sm">
                    <Box 
                      className="flexCenterCenterColumn"
                      gap={"20px"}
                      sx={{ height: "calc(100vh - 90px)" }}>
                      <Typography
                        variant='subtitle1'>
                        Account Verified
                      </Typography>
                      <Button
                        variant='contained'
                        onClick={handleClose}>
                        Close
                      </Button>
                    </Box>
                  </Container>
                  :
                  <></>
              :
                <></>
        }
      </Box>
    </Modal>
  )
}

export default AddCompanyBankAccountModal;