import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  TableSortLabel,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import DateFormatter from '../../../../components/utils/DateFormatter';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';
import NA from '../../../../components/utils/NA';

import { 
  GetBillingCrossBorderPayment 
} from '../../../../redux/actions/billing/billingActions';

import "./styles.scss";

const InternationalPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.bill);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [billingInternationalList, setBillingInternationalList] = useState([]);
  const [billingInternationalTotal, setBillingInternationalTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Billing date",
      sort: "billing_date",
    },
    {
      label: "Debit date",
      sort: null,
    },
    {
      label: "Settlement date",
      sort: "settlement_date",
    },
    // {
    //   label: "Payment for vendor",
    //   sort: "ref_payment_id",
    // },
    // {
    //   label: "Payment amount",
    //   sort: null,
    //   align: "right"
    // },
    {
      label: "Fees",
      sort: "fee_billed",
      align: "right"
    },
    {
      label: "Status",
      sort: "fee_status",
      align: "right"
    },
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetBillingInternational();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      console.log(state)
      formatBillingInternationallist(state.billingCrossBorderPaymentList.records || []);
      setBillingInternationalTotal(state.billingCrossBorderPaymentList.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatBillingInternationallist = (list) => {
    setBillingInternationalList(list);
  }; 

  async function handleGetBillingInternational(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetBillingCrossBorderPayment(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const getStatusChipColor = (status) => {
    switch(status){
      case "Scheduled": return "info";
      case "Processing": return "info";
      case "Paid": return "success";
      case "Failed": return "error";

      default: return "default";
    }
  };

  return (
    <Box>

      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>
      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                billingInternationalList.length === 0
                ?
                <NATable
                  label={"No international bill found"}
                  />
                :
                billingInternationalList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.billing_date ? DateFormatter(row.billing_date) : <NA />}</TableCell>
                    <TableCell>{row?.ref_payment_id?.debit_date ? DateFormatter(row?.ref_payment_id?.debit_date) : <NA />}</TableCell>
                    <TableCell>{row.settlement_date ? DateFormatter(row.settlement_date) : <NA />}</TableCell>
                    {/* <TableCell>
                      {
                        row?.ref_payment_id?.ref_vendor_id
                        ?
                        <Box
                          className="flexCenter_Row"
                          gap={"10px"}>
                          <NameAvatar
                            color={row?.ref_payment_id?.ref_vendor_id?.color || null}>
                            {row?.ref_payment_id?.ref_vendor_id?.name || "NA"}
                          </NameAvatar>
                          <Typography>
                            {row?.ref_payment_id?.ref_vendor_id?.name || "NA"}
                          </Typography>
                        </Box>
                        :
                        <NA label={"No supplier found"}/>
                      }
                    </TableCell>
                    <TableCell
                      align='right'>
                      <Typography variant='body2' fontWeight={600}>
                        {handleShowAmount(row?.ref_payment_id?.currency, row?.ref_payment_id?.amount || 0)}
                      </Typography>
                    </TableCell> */}
                    <TableCell
                      align='right'>
                      <Typography variant='body2' fontWeight={600}>
                        {handleShowAmount(row.fee_currency, row.fee_billed || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align='right'>
                      <Chip
                        size='small'
                        color={getStatusChipColor(row.fee_status)}
                        label={row.fee_status} 
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={billingInternationalTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default InternationalPage;