import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  Container,  
  Skeleton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography,
} from '@mui/material';

import useCompanyAllowedForInternationlPayments from '../../../../components/hooks/useCompanyAllowedForInternationlPayments';
import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import DateFormatter from '../../../../components/utils/DateFormatter';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';
import NA from '../../../../components/utils/NA';

import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';

import {
  GetWalletBalance 
} from '../../../../redux/actions/international/walletActions';
import { 
  GetWalletActivities 
} from '../../../../redux/actions/international/activitiesActions';

import WalletModal from './WalletModal';

import "./styles.scss";

const WalletPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.international);
  const companyId = useSelectedCompany();
  const companyAllowedForInternationalPayments = useCompanyAllowedForInternationlPayments();
  const { handleShowAmount, handleCalculateAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [walletModaOpen, setWalletModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [wallet, setWallet] = useState(null);

  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesTotal, setActivitiesTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Reference",
      sort: null,
    },
    {
      label: "Created",
      sort: null,
    },
    {
      label: "Settlement",
      sort: null,
    },
    {
      label: "Action",
      sort: null,
    },
    {
      label: "Status",
      sort: null,
    },
    {
      label: "Amount",
      sort: null,
      align: "right"
    },
    {
      label: "Balance",
      sort: null,
      align: "right"
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    if(companyAllowedForInternationalPayments === true){
      handleGetBalance();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(companyAllowedForInternationalPayments === true){
      handleGetActivities(setLoadingActivity);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      setWallet(state.walletBalance || null);
      formatActivitiesList(state.activitiesList.records || []);
      setActivitiesTotal(state.activitiesList.total || 0);
    } catch (err) {}  
  }, [state]);

  const formatActivitiesList = (list) => {
    setActivitiesList(list)
  };

  async function handleGetBalance(customLoading){
    dispatch(GetWalletBalance(companyId, customLoading ? customLoading : setLoading));
  };  

  async function handleGetActivities(customLoading){
    dispatch(GetWalletActivities(companyId, page+1, rowsPerPage, customLoading ? customLoading : setLoading));
  };

  function handleActionlabel(action){
    switch(action){
      case "payment_fee": return "Payment fee";
      case "conversion": return "Conversion";
      case "funding": return "Funding";

      default: return "";
    }
  };

  function handleStatuslabel(status){
    switch(status){
      case "completed": return "Completed";
      case "pending": return "Pending";

      default: return "";
    }
  };

  return (
    companyAllowedForInternationalPayments === true
    ?
      <Box>

        <WalletModal 
          open={walletModaOpen}
          setOpen={setWalletModalOpen}
          title={modalTitle}
          update={update}
          handleGet={async () => {
            await handleGetBalance();
            await handleGetActivities(setLoadingActivity);
          }}
          />

        <Container 
          maxWidth="md">
          <Box
            className="flex__Column"
            gap={"20px"}>
            <Typography
              variant='subtitle2'
              color="grey"
              textAlign={"center"}>
              Balance
            </Typography>
            {
              loading
              ?
              <Skeleton 
                variant="rectangular" 
                sx={{
                  borderRadius: "16px",
                  minWidth: "570px",
                  width: "50%",
                  height: "64.5px",
                  margin: "auto",
                }}
                  />
              :
                wallet
                ?
                <Box
                  className="flexCenterCenterRow"
                  sx={{ padding: "10px" }}>
                  <Typography
                    variant='h3'>
                    {handleShowAmount(wallet?.currency, 
                      handleCalculateAmount(wallet?.currency, wallet?.amount))}
                    {/* {wallet?.currency} 
                    {wallet?.amount} */}
                  </Typography>

                </Box>
                :
                <Box
                  className="flexCenterCenterRow"
                  sx={{ padding: "20px" }}>
                  <NA
                    label={"No wallet found"}/>
                </Box>
            }
            <Box
              className="flexCenterCenterRow"
              gap={"20px"}>
              <Button
                variant='contained'
                onClick={() => {
                  setUpdate({
                    defaultMode: 1
                  });
                  setModalTitle("Add money");
                  setWalletModalOpen(!walletModaOpen);
                }}>
                <Box
                  className="flexCenter_Row"
                  gap={"5px"}>
                  <ArrowUpwardSharpIcon
                    fontSize='small' />
                  Add money
                </Box>
              </Button>
              <Button
                variant='contained'>
                <Box
                  className="flexCenter_Row"
                  gap={"5px"}>
                  <ArrowDownwardSharpIcon
                    fontSize='small' />
                  Withdraw
                </Box>
              </Button>
            </Box>
          </Box>
        </Container>
        <Container 
          maxWidth="md">
          <Typography
            variant='subtitle2'
            color="grey"
            mb={"20px"}>
            Activity
          </Typography>
          {
            loadingActivity
            ?
            <TableLoading />
            :
              <Table className='table'>
                <TableHead>
                  <TableRow>
                    { 
                      tableHeadingsList.map((heading, headingIndex) => (
                        heading.sort === null
                        ?
                        <TableCell
                          key={headingIndex} 
                          align={heading.align || "left"}>{heading.label}</TableCell>
                        :
                        <TableCell
                          key={headingIndex}
                          align={heading.align || "left"}>
                          <TableSortLabel
                            active={sort === heading.sort ? true : false}
                            direction={sortDirection}
                            onClick={() => {
                              handleSort(heading.sort);
                            }}
                          >
                            {heading.label}
                          </TableSortLabel>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    activitiesList.length === 0
                    ?
                    <NATable
                      label={"No activity found"}
                      />
                    :
                    activitiesList.map((row, index) => (
                      <TableRow 
                        key={index}>
                        <TableCell>
                          <Typography
                            className='longText'
                            variant='caption'
                            maxWidth={"350px"}>
                            {row.relatedEntityShortReference || ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {row.createdAt ? DateFormatter(row.createdAt) : <NA />}
                        </TableCell>
                        <TableCell>
                          {row.settlesAt ? DateFormatter(row.settlesAt) : <NA />}
                        </TableCell>
                        <TableCell>
                          {handleActionlabel(row.action) || <NA />}
                        </TableCell>
                        <TableCell>
                          {handleStatuslabel(row.status) || <NA />}
                        </TableCell>
                        <TableCell
                          align='right'>
                          <Box
                            className="flexCenterFERow"
                            gap={"3px"}>
                            <Typography 
                              color={row.type === "credit" ? "green" : "red"}>
                              { row.type === "credit" ? "+" : "-" }
                            </Typography>
                            <span 
                              style={{ 
                                color: row.type === "credit" ? "green" : "red",
                                margin: "0px", 
                                padding: "0px" 
                              }}>
                              {handleShowAmount(row?.currency, 
                                handleCalculateAmount(row?.currency, row?.amount))}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell
                          align='right'>
                          { row?.balanceAmount
                          ? handleShowAmount(row?.currency, 
                            handleCalculateAmount(row?.currency, row?.balanceAmount))
                          : ""}
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={{ borderBottom: 0 }}
                      rowsPerPageOptions={[5, 10, 25]} 
                      colSpan={10}
                      count={activitiesTotal}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page'
                        },
                        native: true
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
          }
        </Container>
      </Box>
    : 
       <Box
        className="flexCenterCenterColumn"
        height={"calc(100vh - 300px)"}>
        <Typography
          variant='subtitle1'
          color={"#434343"}>
          Onboarding required for international payments
        </Typography>
      </Box>
  )
}

export default WalletPage;