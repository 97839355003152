import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip,
  Link,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import TableLoading from '../../../components/loaders/TableLoading';
import NameAvatar from '../../../components/avatars/Avatar';
import NATable from '../../../components/utils/NATable';

import LanguageIcon from '@mui/icons-material/Language';

import { 
  GetVendors 
} from '../../../redux/actions/pay/vendorsActions';
import { 
  GetPayments 
} from '../../../redux/actions/pay/paymentsActions';
import { 
  GetBills,
  GetPaymentFrequencies
} from '../../../redux/actions/pay/billsActions';

import CreateBillModal from '../billsPage/CreateBillModal';
import CreatePaymentModal from '../paymentsPage/CreatePaymentModal';
import CreateInternationalPaymentModal from '../paymentsPage/CreateInternationalPaymentModal';

const VendorsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true); 
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [createBillModalOpen, setCreateBillModalOpen] = useState(false);
  const [createInternationalPaymentModalOpen, setCreateInternationalPaymentModalOpen] = useState(false);
  const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [vendorsList, setVendorsList] = useState([]);
  const [vendorsTotal, setVendorsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Vendor",
      sort: "name",
    },
    {
      label: "Status",
      sort: "is_active",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetVendors();
    dispatch(GetPaymentFrequencies(companyId, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatVendorsList(state.vendorsList.records || []);
      setVendorsTotal(state.vendorsList.total || 0);
    } catch (err) {}
  }, [state]);

  // -------------------- list formatter --------------------
  const formatVendorsList = (list) => {
    setVendorsList(list);
  };

  async function handleGetVendors(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetVendors(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  async function handleGetBills(customLoading){
    await dispatch(GetBills(companyId, page+1, rowsPerPage, "", customLoading ? customLoading : setLoading));
  };

  async function handleGetPayments(customLoading){
    dispatch(GetPayments(companyId, page+1, rowsPerPage, "", customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <CreateInternationalPaymentModal 
        open={createInternationalPaymentModalOpen}
        setOpen={setCreateInternationalPaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayments}
        />

      <CreateBillModal
        open={createBillModalOpen}
        setOpen={setCreateBillModalOpen}
        title={modalTitle}
        onDomesticPayment={(e) => {
          setUpdate(e);
          setCreateBillModalOpen(false);
          setCreatePaymentModalOpen(true);
        }}
        onInternationalPayment={(e) => {
          setUpdate(e);
          setCreateBillModalOpen(false);
          setCreateInternationalPaymentModalOpen(true);
        }}
        update={update}
        handleGet={handleGetBills}
        />

      <CreatePaymentModal 
        open={createPaymentModalOpen}
        setOpen={setCreatePaymentModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayments}
        />     

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>

    {
      loading
      ?
      <TableLoading />
      :
        <Table className='table'>
          <TableHead>
            <TableRow>
              {
                tableHeadingsList.map((heading, headingIndex) => (
                  heading.sort === null
                  ?
                  <TableCell
                    key={headingIndex} 
                    align={heading.align || "left"}>{heading.label}</TableCell>
                  :
                  <TableCell
                    key={headingIndex}
                    align={heading.align || "left"}>
                    <TableSortLabel
                      active={sort === heading.sort ? true : false}
                      direction={sortDirection}
                      onClick={() => {
                        handleSort(heading.sort);
                      }}
                    >
                      {heading.label}
                    </TableSortLabel>
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              vendorsList.length === 0
              ?
              <NATable
                label={"No vendor found"}
                />
              :
              vendorsList.map((row, index) => (
                <TableRow 
                  key={index}>
                  <TableCell>
                    <Box
                      className="flexCenter_Row"
                      gap={"10px"}>
                      <Box
                        className="flexCenter_Row"
                        gap={"10px"}>
                        <NameAvatar 
                          color={row.color} 
                          >
                          {row.name || "NA"}
                        </NameAvatar>
                        <Typography>
                          {row.name || "NA"}
                        </Typography>
                      </Box>
                      
                      {
                        row?.is_international === true
                        &&
                        <Tooltip
                          title={"International vendor"}>
                          <LanguageIcon 
                            sx={{ color: "lightgray" }}
                            fontSize='small'/>
                        </Tooltip>
                      }
                      {
                        (row?.is_international === false && row?.associated_vendor_bank_account.length === 0)
                        &&
                        <Chip 
                          color='error'
                          size='small'
                          label={"No bank account added"}
                          />
                      }
                      {
                        (row?.is_international === false && row?.associated_vendor_bank_account.length !== 0 && row?.associated_vendor_bank_account[0]?.orum_verification_status+"" !== "valid")
                        &&
                        <Chip 
                          color='warning'
                          size='small'
                          label={"Verifying vendor bank account"}
                          />
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size='small'
                      color={row.is_active === true ? "success" : "warning"}
                      label={row.is_active === true ? "Active" : "Inactive"} 
                      />
                  </TableCell>
                  <TableCell>
                    {
                      row.is_active === true && (row?.associated_vendor_bank_account.length !== 0 || 
                          row?.is_international === true)
                      &&
                      <Link
                        onClick={() => {
                          let obj = {
                            defaultMode: 1,
                            defaultVendor: row.vendor_id,
                            isVendorInternational: row.is_international,
                          };

                          if(row?.is_international) {
                            obj = { 
                              ...obj, 
                              currency: row?.associated_international_vendor[0].currency,
                              beneficiaryCountry: row?.associated_international_vendor[0].beneficiary_country,
                              bankCountry: row?.associated_international_vendor[0].bank_country,
                            };
                          };
                          
                          setUpdate(obj);
                          setModalTitle("Create Bill");
                          setCreateBillModalOpen(!createBillModalOpen);
                        }}>New Payment</Link>
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ borderBottom: 0 }}
                rowsPerPageOptions={[5, 10, 25]} 
                colSpan={10}
                count={vendorsTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
          </TableFooter>
        </Table>
    }
  </Box>
  )
}

export default VendorsPage;